import {
  CreateVariables,
  TableBulkTrashVariables,
  TableUpdateVariables,
} from "./../models/app";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setNextToken, setSelected } from "../store/ducks/table";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { Table } from "../models";
import {
  TableGetVariables,
  TableListingVariables,
  Option,
} from "../models/app";
import { CreateTableInput, UpdateTableInput } from "../models/GQL_API";
import { GraphQLQuery } from "@aws-amplify/api";
import { getTable, listTables } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { createTable, deleteTable, updateTable } from "../graphql/mutations";
import { onCreateTable } from "../graphql/subscriptions";
import { getUAEDateTimeFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  const conceptsSelectedFilters = useSelector(
    (state: any) => state.concepts.selectedFilters
  );

  async function fetch(params: TableListingVariables) {
    const { conceptID, searchText, limit, name } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (name) {
        filter.name = { contains: name };
      }

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      // Concept Filter
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.or = or;
      } else {
        filter.conceptID = { eq: conceptID };
      }

      const ListData: any = await API.graphql<Table>({
        query: listTables,
        variables: { filter, limit, nextToken: nextToken },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = ListData.data.listTables.nextToken;
      const listing = ListData.data.listTables.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  const getName = (params: TableGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Table) => model.id === id);
      return model ? model.name : "";
    }

    return "";
  };

  async function get(params: TableGetVariables) {
    try {
      const { id, listing } = params;

      let single: Table | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Table>({
          query: getTable,
          variables: { id },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        single = listing.data.getTable;
      }

      return single;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return null;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      if (data.name) {
        const params: TableListingVariables = {
          conceptID: data.conceptID,
          searchText: "",
          startIndex: 0,
          limit: 1000,
          name: data.name.toLowerCase().trim(),
        };

        const tables = await fetch(params);

        if (tables!.length > 0) {
          const error = new Error("Table already exists");
          return showError(error);
        }
      }

      const createInput: CreateTableInput = {
        conceptID: data.conceptID,
        label: data.label,
        name: data.name.toLowerCase(),
        width: parseInt(data.width),
        height: parseInt(data.height),
        image: data.image
          ? data.image.fileUrl
            ? data.image.fileUrl
            : data.image
          : "",
        deleted: "0",
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.capacity) createInput.capacity = parseInt(data.capacity);

      await API.graphql<Table>({
        query: createTable,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function update(params: TableUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original: any = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateTableInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase().trim() : original!.name,
        label: data.label ? data.label.toLowerCase().trim() : original!.label,
        width: data.width ? parseInt(data.width) : original!.width,
        height: data.height ? parseInt(data.height) : original!.height,
        capacity: data.capacity ? parseInt(data.capacity) : original!.capacity,
        image: data.image
          ? data.image.fileUrl
            ? data.image.fileUrl
            : data.image
          : original!.image,

        _version: original._version,
      };

      await API.graphql<Table>({
        query: updateTable,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: TableGetVariables) {
    try {
      const original: any = await get(params);

      if (original) {
        const updateInput: UpdateTableInput = {
          id: original.id,
          deleted: "1",
          _version: original._version,
        };

        await API.graphql<Table>({
          query: updateTable,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: TableBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: TableGetVariables) {
    try {
      const { id, listing } = params;

      await API.graphql<Table>({
        query: deleteTable,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  async function exportAll(params: TableListingVariables) {
    try {
      const data = await fetch(params);
      let exportedData: any[] = [];

      for (let table of data!) {
        let row: any = { ...table };

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  function options(listing: Table[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  async function getOnline(id: string, session = false) {
    try {
      const table: any = await API.graphql<GraphQLQuery<Table>>({
        query: getTable,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return table.data?.getTable;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "label",
      numeric: false,
      disablePadding: false,
      label: "Label",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "label"];

  const api: any = {};

  api[`${listingName}Model`] = Table as any;
  api[`${listingName}CreateSubscription`] = onCreateTable;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Table[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
