import { MenuRotation, ModelSortDirection } from "./../models/GQL_API";
import { ListingByConceptVariables } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import {
  categoryByConceptID,
  choiceByConceptID,
  choiceGroupsByConceptID,
  mainCategoryByConceptID,
  menuItemByConceptID,
  menuRotationByConceptID,
  priceByConceptID,
  subCategoryByConceptID,
} from "../graphql/queries";

import {
  setNextToken as setNextTokenMenuRotation,
  setLastIndex as setLastIndexMenuRotation,
  setPagination as setPaginationMenuRotation,
  nextAction as nextActionMenuRotation,
  setPreviousToken as setPreviousTokenMenuRotation,
  setFilter as setFilterMenuRotation,
  setListing as setListingMenuRotation,
} from "../store/ducks/menuRotation";

import {
  setNextToken as setNextTokenMainCategory,
  setLastIndex as setLastIndexMainCategory,
  setPagination as setPaginationMainCategory,
  nextAction as nextActionMainCategory,
  setPreviousToken as setPreviousTokenMainCategory,
  setFilter as setFilterMainCategory,
  setListing as setListingMainCategory,
} from "../store/ducks/mainCategory";

import {
  setNextToken as setNextTokenSubCategory,
  setLastIndex as setLastIndexSubCategory,
  setPagination as setPaginationSubCategory,
  nextAction as nextActionSubCategory,
  setPreviousToken as setPreviousTokenSubCategory,
  setFilter as setFilterSubCategory,
  setListing as setListingSubCategory,
} from "../store/ducks/subCategory";

import {
  setNextToken as setNextTokenCategory,
  setLastIndex as setLastIndexCategory,
  setPagination as setPaginationCategory,
  nextAction as nextActionCategory,
  setPreviousToken as setPreviousTokenCategory,
  setFilter as setFilterCategory,
  setListing as setListingCategory,
} from "../store/ducks/category";

import {
  setNextToken as setNextTokenMenuItem,
  setLastIndex as setLastIndexMenuItem,
  setPagination as setPaginationMenuItem,
  nextAction as nextActionMenuItem,
  setPreviousToken as setPreviousTokenMenuItem,
  setFilter as setFilterMenuItem,
  setListing as setListingMenuItem,
} from "../store/ducks/menuItem";

import {
  setNextToken as setNextTokenChoiceGroup,
  setLastIndex as setLastIndexChoiceGroup,
  setPagination as setPaginationChoiceGroup,
  nextAction as nextActionChoiceGroup,
  setPreviousToken as setPreviousTokenChoiceGroup,
  setFilter as setFilterChoiceGroup,
  setListing as setListingChoiceGroup,
} from "../store/ducks/choiceGroup";

import {
  setNextToken as setNextTokenChoice,
  setLastIndex as setLastIndexChoice,
  setPagination as setPaginationChoice,
  nextAction as nextActionChoice,
  setPreviousToken as setPreviousTokenChoice,
  setFilter as setFilterChoice,
  setListing as setListingChoice,
} from "../store/ducks/choice";

import { Pages } from "../constants/enums";

const useGeneralPagination = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError } = useApp();

  const [
    setNextToken,
    setLastIndex,
    setPagination,
    nextAction,
    setPreviousToken,
    setFilter,
    setListing,
  ] =
    singleName === "menuRotation"
      ? [
          setNextTokenMenuRotation,
          setLastIndexMenuRotation,
          setPaginationMenuRotation,
          nextActionMenuRotation,
          setPreviousTokenMenuRotation,
          setFilterMenuRotation,
          setListingMenuRotation,
        ]
      : singleName === "category"
      ? [
          setNextTokenCategory,
          setLastIndexCategory,
          setPaginationCategory,
          nextActionCategory,
          setPreviousTokenCategory,
          setFilterCategory,
          setListingCategory,
        ]
      : singleName === "subCategory"
      ? [
          setNextTokenSubCategory,
          setLastIndexSubCategory,
          setPaginationSubCategory,
          nextActionSubCategory,
          setPreviousTokenSubCategory,
          setFilterSubCategory,
          setListingSubCategory,
        ]
      : singleName === "mainCategory"
      ? [
          setNextTokenMainCategory,
          setLastIndexMainCategory,
          setPaginationMainCategory,
          nextActionMainCategory,
          setPreviousTokenMainCategory,
          setFilterMainCategory,
          setListingMainCategory,
        ]
      : singleName === "menuItem"
      ? [
          setNextTokenMenuItem,
          setLastIndexMenuItem,
          setPaginationMenuItem,
          nextActionMenuItem,
          setPreviousTokenMenuItem,
          setFilterMenuItem,
          setListingMenuItem,
        ]
      : singleName === "choiceGroup"
      ? [
          setNextTokenChoiceGroup,
          setLastIndexChoiceGroup,
          setPaginationChoiceGroup,
          nextActionChoiceGroup,
          setPreviousTokenChoiceGroup,
          setFilterChoiceGroup,
          setListingChoiceGroup,
        ]
      : // singleName === "choice" ?
        [
          setNextTokenChoice,
          setLastIndexChoice,
          setPaginationChoice,
          nextActionChoice,
          setPreviousTokenChoice,
          setFilterChoice,
          setListingChoice,
        ];
  const session = useSelector((state: any) => state.app.session);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);
  const preparationAreasSelectedFilters = useSelector(
    (state: any) => state.preparationAreas.selectedFilters
  );
  const menuRotationsSelectedFilters = useSelector(
    (state: any) => state.menuRotations.selectedFilters
  );

  // Pagination State
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );
  const lastIndex = useSelector(
    (state: any) => state[`${listingName}`]["lastIndex"]
  );

  const storedLimit = useSelector(
    (state: any) => state[`${listingName}`]["limit"]
  );
  const previousTokens = useSelector(
    (state: any) => state[`${listingName}`]["previousTokens"]
  );
  let paginationFilter = useSelector(
    (state: any) => state[`${listingName}`]["filter"]
  );
  let paginationListing = useSelector(
    (state: any) => state[`${listingName}`]["pagination"]
  );

  // Fetch with pagination, using concept index
  async function fetch(params: ListingByConceptVariables, queryName: string) {
    try {
      const { conceptID, searchText, limit, startIndex, moveForward } = params;

      if (!conceptID) {
        return [];
      }

      let requestLimit = limit ? limit : storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;

      /* Filter Section */
      const filter: any = {
        deleted: { eq: "0" },
      };

      filter.or = [];
      filter.and = [];

      // Preparation area filter
      if (
        queryName.includes("category") &&
        preparationAreasSelectedFilters &&
        preparationAreasSelectedFilters.length > 0
      ) {
        let or = [];
        for (let filter of preparationAreasSelectedFilters) {
          if (filter) or.push({ preparationAreaID: { eq: filter.id } });
        }
        filter.and.push({ or: or });
      }

      // Menu Rotations filter
      if (
        queryName.includes("subCategory") &&
        menuRotationsSelectedFilters &&
        menuRotationsSelectedFilters.length > 0
      ) {
        let or = [];
        for (let filter of menuRotationsSelectedFilters) {
          if (filter) or.push({ menuRotationsID: { contains: filter.id } });
        }
        filter.and.push({ or: or });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      // Get token from previous tokens
      if (!moveForward) {
        const updatedPreviousTokens = [...requestPreviousTokens];
        updatedPreviousTokens.pop();

        requestToken =
          updatedPreviousTokens.length >= 2
            ? updatedPreviousTokens[updatedPreviousTokens.length - 2]
            : null;

        dispatch(
          setNextToken(updatedPreviousTokens[updatedPreviousTokens.length - 1])
        );
        dispatch(setPreviousToken(updatedPreviousTokens));
      }

      // Check filter change
      if (
        startIndex === 0 ||
        (paginationFilter &&
          paginationFilter.toString() !== [searchText, conceptID].toString())
      ) {
        dispatch(setPagination([]));
        dispatch(setNextToken(null));
        dispatch(setLastIndex(0));

        requestToken = null;
      }

      // NEED TEST
      // Get from paginationListing in case of moving back instead of query to make it super fast
      // if (
      //   paginationListing.length > 0 &&
      //   selectedConcept === paginationListing[0]?.conceptID &&
      //   ((lastIndex >= startIndex &&
      //     lastIndex !== null &&
      //     limit < paginationListing.length) ||
      //     !nextToken)
      // ) {
      //   return paginationListing.slice(startIndex, startIndex + limit);
      // }

      // Get more item by check limit and returned rows
      let queryData: any = null;
      let listing: any[] = [];
      let partialList: any[] = [];
      let currentNextToken;

      // Detect query
      let query: any = await detectQuery();
      if (!query) return [];

      do {
        // Query using index
        let variables;
        const baseVariables = {
          filter,
          conceptID,
          limit: requestLimit,
          nextToken: requestToken,
          ModelSortDirection: ModelSortDirection.ASC,
        };

        // Check if the query name includes specific keywords
        if (
          ["menuItem", "price", "choice"].some((keyword) =>
            queryName.includes(keyword)
          )
        ) {
          // Add name filter if searchText exists
          variables = searchText?.trim()
            ? {
                ...baseVariables,
                name: { beginsWith: searchText.toLocaleLowerCase().trim() },
              }
            : baseVariables;
        } else {
          // For queries not including specific keywords
          if (searchText?.trim()) {
            filter.name = { contains: searchText.toLocaleLowerCase().trim() };
          }
          variables = baseVariables;
        }

        queryData = await API.graphql<GraphQLQuery<any>>({
          query: query,
          variables: variables,
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        // Filter list using selected concept
        currentNextToken = queryData.data[queryName].nextToken;
        partialList = queryData.data[queryName].items;
        listing = [...listing, ...partialList];

        if (moveForward) {
          // Save current nextToken, and add it to token list
          dispatch(nextAction(currentNextToken, requestPreviousTokens));
          dispatch(setNextToken(currentNextToken));
          dispatch(setLastIndex(startIndex));
        }

        requestToken = currentNextToken;
      } while (
        (listing.length < limit || listing.length / limit < 0) &&
        currentNextToken !== null
      );

      // Sort result by precedence in ascending order
      // const listSorted = listing.sort((a: any, b: any) => {
      //   return a.precedence - b.precedence;
      // });

      // Store list history
      let list =
        selectedConcept === paginationListing[0]?.conceptID
          ? paginationListing.concat(listing)
          : listing;

      dispatch(setFilter([searchText, conceptID]));
      dispatch(setListing(listing));
      dispatch(setPagination(list));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  // Fetch all data filtered without pagination using concept index
  async function fetchAllByConceptID(
    params: ListingByConceptVariables,
    queryName: string,
    menuRotations?: MenuRotation[]
  ) {
    try {
      const { conceptID, searchText, limit } = params;

      if (!conceptID) {
        return [];
      }

      const filter: any = {
        deleted: { eq: "0" },
      };
      filter.or = [];
      filter.and = [];

      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      // Preparation area filter
      if (
        queryName.includes("category") &&
        preparationAreasSelectedFilters &&
        preparationAreasSelectedFilters.length > 0
      ) {
        let or = [];
        for (let filter of preparationAreasSelectedFilters) {
          if (filter) or.push({ preparationAreaID: { eq: filter.id } });
        }
        filter.and.push({ or: or });
      }

      // Menu Rotations filter
      if (
        queryName.includes("subCategory") &&
        menuRotations &&
        menuRotations.length > 0 &&
        (menuRotationsSelectedFilters == null ||
          menuRotationsSelectedFilters.length === 0)
      ) {
        let or = [];
        for (let filter of menuRotations) {
          if (filter) or.push({ menuRotationsID: { contains: filter.id } });
        }
        filter.and.push({ or: or });
      }

      // Menu Rotations filter
      if (
        queryName.includes("subCategory") &&
        menuRotationsSelectedFilters &&
        menuRotationsSelectedFilters.length > 0
      ) {
        let or = [];
        for (let filter of menuRotationsSelectedFilters) {
          if (filter) or.push({ menuRotationsID: { contains: filter.id } });
        }
        filter.and.push({ or: or });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      // Detect query
      let query: any = await detectQuery();
      if (!query) return [];

      let queryData: any;
      let nextToken = null;
      let allListing: any[] = [];
      let subListing: any[];

      do {
        const variables = {
          filter,
          conceptID: conceptID,
          limit: limit ?? 10000,
          nextToken,
        };
        // console.log(`${listingName} Variables: ${JSON.stringify(variables)}`);

        queryData = await API.graphql<GraphQLQuery<any>>({
          query: query,
          variables: variables,
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        nextToken = queryData.data[queryName].nextToken;
        subListing = queryData.data[queryName].items;

        // Add new list to original one
        allListing = allListing.concat(subListing);
      } while (nextToken && nextToken.length > 0);

      // Sort result by precedence in ascending order
      // return allListing.sort((a: any, b: any) => {
      //   return a.precedence - b.precedence;
      // });

      return allListing;
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function detectQuery() {
    let query: any = null;

    try {
      switch (listingName) {
        case Pages.MENU_ROTATIONS:
          query = menuRotationByConceptID;
          break;
        case Pages.MAIN_CATEGORIES:
          query = mainCategoryByConceptID;
          break;
        case Pages.SUB_CATEGORIES:
          query = subCategoryByConceptID;
          break;
        case Pages.CATEGORIES:
          query = categoryByConceptID;
          break;
        case Pages.MENU_ITEMS:
          query = menuItemByConceptID;
          break;
        case Pages.CHOICE_GROUPS:
          query = choiceGroupsByConceptID;
          break;
        case Pages.CHOICES:
          query = choiceByConceptID;
          break;
        case Pages.PRICES:
          query = priceByConceptID;
          break;
        default:
          console.error(
            `Fetch Error: Failed to detect module ${listingName} query.`
          );
          break;
      }

      return query;
    } catch (err) {
      console.log(err);
      showError(err);
      return query;
    }
  }

  const api: any = {};
  api[`generalFetch`] = fetch;
  api[`generalFetchAllByConceptID`] = fetchAllByConceptID;

  return api;
};

export default useGeneralPagination;
