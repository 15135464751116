import {
  CreatePreparationAreaInput,
  UpdatePreparationAreaInput,
} from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { PreparationArea } from "../models";
import {
  createPreparationArea,
  updatePreparationArea,
} from "../graphql/mutations";
import { getPreparationArea, listPreparationAreas } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import {
  setAllListing,
  setFilters,
  setSelectedFilters,
  setLastIndex,
  setListing,
  setNextToken,
  setPagination,
  setUserAreas,
} from "../store/ducks/preparationArea";
import { getDateFormatted, getUAEDateTimeFormatted } from "../helpers/utils";
import { onCreatePreparationArea } from "../graphql/subscriptions";
import { getTimeInUAE } from "../helpers/utils";

const usePreparationArea = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const session = useSelector((state: any) => state.app.session);
  const accountsSelected = useSelector((state: any) => state.accounts.selected);

  const prepAreasAllListing = useSelector(
    (state: any) => state.preparationAreas.allListing
  );

  const nextToken = useSelector(
    (state: any) => state.preparationAreas.nextToken
  );
  const lastIndex = useSelector(
    (state: any) => state.preparationAreas.lastIndex
  );
  const paginationListing = useSelector(
    (state: any) => state.preparationAreas.pagination
  );

  async function fetch(params: any) {
    try {
      const { accountID, searchText, limit, startIndex } = params;
      let filter: any = {
        accountID: { eq: accountID ? accountID : accountsSelected.id },
        deleted: { eq: "0" },
      };
      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      const listing: any = await API.graphql<GraphQLQuery<PreparationArea>>({
        query: listPreparationAreas,
        variables: { filter, limit: 1000 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      return listing.data.listPreparationAreas.items;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchAll(params: any) {
    try {
      const { accountID, searchText, limit, startIndex } = params;
      const filter: any = {
        accountID: { eq: accountID ? accountID : accountsSelected.id },
        deleted: { eq: "0" },
      };
      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        prepAreasAllListing.length === 0 ||
        accountsSelected.id !== prepAreasAllListing[0].accountID
      ) {
        const listing: any = await API.graphql<GraphQLQuery<PreparationArea>>({
          query: listPreparationAreas,
          variables: { filter, limit: limit ?? 1000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        return listing.data.listPreparationAreas.items;
      } else {
        return prepAreasAllListing;
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function fetchByUser(params: any) {
    try {
      const { userID, accountID, limit } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };
      filter.accountID = { eq: accountID ? accountID : accountsSelected.id };

      const listing: any = await API.graphql<GraphQLQuery<PreparationArea>>({
        query: listPreparationAreas,
        variables: { filter, limit: limit ?? 1000 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      let list: any = [];
      for (let i = 0; i < listing.data.listPreparationAreas.items.length; i++) {
        if (listing.data.listPreparationAreas.items[i].users.includes(userID)) {
          list.push(listing.data.listPreparationAreas.items[i]);
        }
      }
      return list;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const PreparationArea: any = await API.graphql({
        query: getPreparationArea,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return PreparationArea.data.getPreparationArea;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    try {
      const createInput: CreatePreparationAreaInput = {
        name: data.name.toLowerCase().trim(),
        precedence: data.precedence ? data.precedence : "0",
        accountID: data.accountID,
        isAutoAccept: data.isAutoAccept ? data.isAutoAccept : false,
        hasKDS: data.hasKDS ? data.hasKDS : false,
        printerIP: data.printerIP ? data.printerIP : "",
        printerPort: data.printerPort ? data.printerPort : "9100",
        hasPrinter: data.hasPrinter ? data.hasPrinter : false,
        users: data.users ? data.users : [],
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      const preparationArea = await API.graphql<GraphQLQuery<PreparationArea>>({
        query: createPreparationArea,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));
      showConfirm(`New Service Area has been created successfully`);

      return preparationArea;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      const updateInput: UpdatePreparationAreaInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase().trim() : original.name,
        precedence: data.precedence ? data.precedence : original.precedence,
        isAutoAccept: data.isAutoAccept,
        hasKDS: data.hasKDS,
        printerIP: data.printerIP ? data.printerIP : "",
        printerPort: data.printerPort ? data.printerPort : "9100",
        hasPrinter: data.hasPrinter,
        users: data.users ? data.users : original.users,
        _version: original._version,
      };

      const preparationArea: any = await API.graphql<
        GraphQLQuery<PreparationArea>
      >({
        query: updatePreparationArea,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));
      showConfirm(`Service Area has been updated successfully`);

      return preparationArea.data.updatePreparationArea;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: PreparationArea[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = PreparationArea as any;
  api[`${listingName}CreateSubscription`] = onCreatePreparationArea;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchByUser`] = fetchByUser;
  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: PreparationArea[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeUserAreas`] = (listing: PreparationArea[]) =>
    dispatch(setUserAreas(listing));
  api[`${listingName}ChangeAllListing`] = (listing: PreparationArea[]) => {
    dispatch(setAllListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };

  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };

  return api;
};

export default usePreparationArea;
