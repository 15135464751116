import {
  CreateVariables,
  ReservationStatusBulkTrashVariables,
  ReservationStatusUpdateVariables,
  Option,
} from "./../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch } from "react-redux";
import {
  setFilters,
  setListing,
  setSelected,
  setSelectedFilters,
} from "../store/ducks/status";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import useAccount from "./useAccount";
import { ReservationStatus } from "../models";
import { listReservationStatuses } from "../graphql/queries";
import {
  ReservationStatusGetVariables,
  ReservationStatusListingVariables,
} from "../models/app";
import { CreateReservationStatusInput } from "../models/GQL_API";
import { getReservationStatus } from "../graphql/queries";
import { getUAEDateTimeFormatted } from "../helpers/utils";
const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const { accountsFetchByDomainOnline } = useAccount("accounts", "account");

  async function fetch(params: ReservationStatusListingVariables) {
    const { accountID, startIndex, limit, searchText } = params;

    try {
      const listing = await DataStore.query(
        ReservationStatus as any,
        (model: any) => {
          model.accountID("eq", accountID).deleted("eq", "0");

          if (searchText.length > 0)
            model.name("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      // dispatch(setListing(listing));
      // dispatch(setFilters(listing.map((model: any) => model.name)));

      return listing;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function fetchOnline(limit: number, session: any) {
    try {
      let account = await accountsFetchByDomainOnline(session ? true : false);
      const listing: any = await API.graphql({
        query: listReservationStatuses,
        variables: {
          limit,
          filters: {
            accountID: account.id,
          },
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return listing.data.listReservationStatuses.items;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: ReservationStatusGetVariables): string => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model: ReservationStatus | undefined = listing.find(
        (model: ReservationStatus) => model.id === id
      );

      return model ? model.name : "";
    }

    return "";
  };

  async function get(params: ReservationStatusGetVariables) {
    const { id, listing } = params;

    try {
      const single: ReservationStatus | undefined =
        listing.length === 0
          ? await DataStore.query(ReservationStatus as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }
  async function getOnline(id: string, isAuth: boolean) {
    try {
      const reservationStatus: any = await API.graphql({
        query: getReservationStatus,
        variables: { id: id },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return reservationStatus.data.getReservationStatus;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateReservationStatusInput = {
        accountID: data.accountID,
        name: data.name.toLowerCase().trim(),
        category: data.category,
        deleted: "0",
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      await DataStore.save(new ReservationStatus(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function update(params: ReservationStatusUpdateVariables) {
    const { id, listing, data } = params;
    try {
      const original = await get({ id, listing });

      await DataStore.save(
        ReservationStatus.copyOf(original!, (updated) => {
          updated.name = data.name ? data.name : original!.name;
          updated.category = data.category ? data.category : original!.category;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ReservationStatusGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        ReservationStatus.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function bulkTrash(params: ReservationStatusBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ReservationStatusGetVariables) {
    const { id, listing } = params;
    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  function options(listing: ReservationStatus[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "category"];

  const api: any = {};

  api[`${listingName}Model`] = ReservationStatus as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: ReservationStatus[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };
  api[`${listingName}ChangeSelected`] = (selected: Set<string>) =>
    dispatch(setSelected(selected));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useResource;
