import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import {
  CommentBulkTrashVariables,
  CommentGetVariables,
  CommentListingVariables,
  CommentUpdateVariables,
  CreateVariables,
} from "./../models/app";
import { DataStore, SortDirection, API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/comment";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { Comment } from "../models";
import { CreateCommentInput } from "../models/GQL_API";
import { listComments } from "../graphql/queries";
import { onCreateComment } from "../graphql/subscriptions";
import { getUAEDateTimeFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const session = useSelector((state: any) => state.app.session);

  async function fetchAll(params: CommentListingVariables) {
    const { searchText, startIndex, limit, guestID, bookingID } = params;
    try {
      const listing = await DataStore.query(
        Comment as any,
        (model: any) => {
          // model.deleted("eq", "0");

          if (guestID) model.customerId("eq", guestID);
          if (bookingID) model.bookingId("eq", bookingID);

          if (searchText.length > 0)
            model.message("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function fetchAllOnline(params: CommentListingVariables) {
    const { guestID, bookingID } = params;
    let filter: any = {};

    if (bookingID) {
      filter.bookingId = { eq: bookingID };
    }

    if (guestID) {
      filter.customerId = { eq: guestID };
    }

    const limit: any = 5000;
    let nextToken: any = null;
    const allComments: any = [];

    try {
      do {
        const listing: any = await API.graphql({
          query: listComments,
          variables: { filter, limit, nextToken },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        allComments.push(...listing.data.listComments.items);
        nextToken = listing.data.listComments.nextToken;
      } while (nextToken);

      return allComments;
    } catch (err) {
      throw err;
    }
  }

  async function fetch(params: CommentListingVariables) {
    const { searchText, startIndex, limit, guestID, bookingID } = params;

    try {
      const listing = await DataStore.query(
        Comment as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (guestID) model.customerId("eq", guestID);
          if (bookingID) model.bookingId("eq", bookingID);

          if (searchText.length > 0)
            model.message("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: CommentGetVariables) {
    const { id, listing } = params;

    try {
      const single: Comment | undefined =
        listing.length === 0
          ? await DataStore.query(Comment as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateCommentInput = {
        message: data.message,
        customerId: data.customerId ? data.customerId : "",
        bookingId: data.bookingId ? data.bookingId : "",
        deleted: "0",
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await DataStore.save(new Comment(createInput as any));

      showConfirm(`New ${singleName} has been created successfully`);

      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: CommentUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      await DataStore.save(
        Comment.copyOf(original!, (updated) => {
          updated.message = data.message ? data.message : original!.message;
          updated.customerId = data.customerId
            ? data.customerId
            : original!.customerId;
          updated.customerId = data.customerId
            ? data.customerId
            : original!.customerId;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: CommentGetVariables) {
    try {
      const original = await get(params);

      const updated: Comment = await DataStore.save(
        Comment.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);

      return updated;
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: CommentBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: CommentGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "category"];

  const api: any = {};

  api[`${listingName}Model`] = Comment as any;
  api[`${listingName}CreateSubscription`] = onCreateComment;

  api[`${listingName}Options`] = [];
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchAllOnline`] = fetchAllOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Comment[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
