import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/attachment";
import { Attachment } from "../models";
import {
  AttachmentBulkTrashVariables,
  GetVariables,
  UpdateVariables,
  CreateVariables,
  ListingVariables,
  HeadCell,
} from "../models/app";
import {
  CreateAttachmentInput,
  UpdateAttachmentInput,
} from "../models/GQL_API";
import {
  createAttachment,
  deleteAttachment,
  updateAttachment,
} from "../graphql/mutations";
import useApp from "./useApp";
import { getAttachment, listAttachments } from "../graphql/queries";
import { onCreateAttachment } from "../graphql/subscriptions";
import { getUAEDateTimeFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const attachmentsListing = useSelector(
    (state: any) => state.attachments.listing
  );

  async function fetch(params: ListingVariables) {
    const { limit } = params;

    try {
      if (attachmentsListing.length === 0 || limit <= 30) {
        const ListData: any = await API.graphql<Attachment>({
          query: listAttachments,
          variables: { limit },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        const listing = ListData.data.listAttachments.items;

        return listing;
      } else {
        return attachmentsListing;
      }
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      let single: Attachment | undefined;

      const listing: any = await API.graphql<Attachment>({
        query: getAttachment,
        variables: { id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      single = listing.data.getAttachment;

      return single;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateAttachmentInput = {
        mediaID: data.mediaID,
        fileUrl: data.fileUrl,
        filename: data.filename,
        filetype: data.filetype,
        fileSize: data.fileSize,
        alternativeText: data.alternativeText,
        caption: data.caption,
        description: data.description,
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await API.graphql<Attachment>({
        query: createAttachment,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      if (data.listing) {
        dispatch(setListing([...data.listing, model]));
      }

      return model;
    } catch (err) {
      throw err;
    }
  }

  async function update(params: UpdateVariables) {
    const { id, data } = params;

    try {
      const original: any = await get({ id });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateAttachmentInput = {
        id: original.id,

        fileUrl: data.fileUrl ? data.fileUrl : original!.fileUrl,
        alternativeText: data.alternativeText
          ? data.alternativeText
          : original!.alternativeText,

        _version: original._version,
      };

      await API.graphql<Attachment>({
        query: updateAttachment,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      return `${singleName} has been updated successfully`;
    } catch (err) {
      throw err;
    }
  }

  async function trash(params: GetVariables) {
    try {
      const { id } = params;

      const toDelete: Attachment | undefined = await get(params);

      if (!toDelete) throw new Error("Item cannot be found!");

      await API.graphql<Attachment>({
        query: deleteAttachment,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function bulkTrash(params: AttachmentBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: GetVariables) {
    try {
      const { id } = params;

      const toDelete: Attachment | undefined = await get(params);

      if (!toDelete) throw new Error("Item cannot be found!");

      await API.graphql<Attachment>({
        query: deleteAttachment,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      return `${singleName} has been deleted successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "category"];

  const api: any = {};

  api[`${listingName}Model`] = Attachment as any;
  api[`${listingName}CreateSubscription`] = onCreateAttachment;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Attachment[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useResource;
