import { listConcepts } from "./../graphql/queries";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setBranches,
  setListing,
  setSelected,
  setFilters,
  setSelectedFilters,
  setNextToken,
} from "../store/ducks/concept";
import { Concept } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { CreateConceptInput, UpdateConceptInput } from "../models/GQL_API";
import {
  ConceptBulkTrashVariables,
  CreateVariables,
  ConceptGetVariables,
  ConceptListingVariables,
  Option,
} from "../models/app";
import { getConcept } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { LOCAL_STORAGE, Languages } from "../constants/enums";
import {
  createConcept,
  deleteConcept,
  updateConcept,
} from "../graphql/mutations";
import { onCreateConcept } from "../graphql/subscriptions";
import { getUAEDateTimeFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const language = useSelector((state: any) => state.accounts.language);

  async function fetch(params: ConceptListingVariables) {
    const { accountID, searchText, limit } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      if (accountID) filter.accountID = { eq: accountID };

      const conceptListing: any = await API.graphql({
        query: listConcepts,
        variables: {
          filter,
          limit: limit || 1000,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = conceptListing.data.listConcepts.nextToken;
      const listing: any = conceptListing.data.listConcepts.items;

      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: ConceptGetVariables) {
    const { id, listing } = params;

    try {
      let single: Concept | undefined;
      if (listing.length > 0) {
        single = listing.find((resource: any) => resource.id === id);
      } else {
        const listing: any = await API.graphql<Concept>({
          query: getConcept,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getConcept;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(id: string, isAuth: boolean) {
    try {
      const user: any = await API.graphql({
        query: getConcept,
        variables: { id: id },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return user.data.getConcept;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    let multiLanguages: any = [[`${Languages.ENGLISH}-name`, data.name]];
    if (data.description) {
      multiLanguages.push([
        `${Languages.ENGLISH}-description`,
        data.description,
      ]);
    }
    multiLanguages = JSON.stringify(multiLanguages);

    try {
      const seats = [
        data.seatTypes_Bar ? "Bar" : null,
        data.seatTypes_Table ? "Table" : null,
      ];
      const areas = [
        data.areas_Indoor ? "Indoor" : null,
        data.areas_Outdoor ? "Outdoor" : null,
      ];
      const createInput: CreateConceptInput = {
        accountID: data.accountID,
        name: data.name.toLowerCase().trim(),
        description: data.description ? data.description : "",
        location: data.location ? data.location : "",
        type: data.type ? data.type : "",
        seatTypes: seats ? seats : [],
        areas: areas ? areas : [],
        image: data.image ? data.image : "",
        logo: data.logo ? data.logo : "",
        album: data.album ? data.album : [],
        merchantID: data.merchantID ? data.merchantID : "",
        merchantUsername: data.merchantUsername ? data.merchantUsername : "",
        merchantPassword: data.merchantPassword ? data.merchantPassword : "",
        merchantIntegrationNumber: data.merchantIntegrationNumber
          ? data.merchantIntegrationNumber
          : "",
        merchantAPIKey: data.merchantAPIKey ? data.merchantAPIKey : "",
        autoSendOrder: data.autoSendOrder ? data.autoSendOrder : false,
        isPinRequired: data.isPinRequired ? data.isPinRequired : false,
        needAssistance: data.needAssistance ? data.needAssistance : false,
        spaBooking: data.spaBooking ? data.spaBooking : false,
        showerBooking: data.showerBooking ? data.showerBooking : false,
        cigarMenu: data.cigarMenu ? data.cigarMenu : false,
        viewOnly: data.viewOnly ? data.viewOnly : false,
        receiveWaiters: [],
        WaiterNotificationOption: "",
        multiLanguages: multiLanguages,

        deleted: "0",
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      await API.graphql<Concept>({
        query: createConcept,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: any) {
    const { id, listing, data, newReceiveWaiter, delteWaiterID } = params;

    try {
      const original: any = await get({ id, listing: [] });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      if (!newReceiveWaiter && !delteWaiterID) {
        let multiLanguages: any = [];
        if (original.multiLanguages) {
          multiLanguages = new Map(JSON.parse(original.multiLanguages));
          if (data.name) {
            multiLanguages.set(`${language}-name`, data.name);
          }
          if (data.description) {
            multiLanguages.set(`${language}-description`, data.description);
          }
          multiLanguages = JSON.stringify(Array.from(multiLanguages.entries()));
        } else {
          if (data.name) {
            multiLanguages.push([`${language}-name`, data.name]);
          }
          if (data.description) {
            multiLanguages.push([`${language}-description`, data.description]);
          }
          multiLanguages = JSON.stringify(multiLanguages);
        }

        const updateInput: UpdateConceptInput = {
          id: original.id,
          name:
            data.name && language === Languages.ENGLISH
              ? data.name.toLowerCase().trim()
              : original!.name,
          description:
            data.description && language === Languages.ENGLISH
              ? data.description
              : original!.description,
          merchantID: data.merchantID,
          merchantUsername: data.merchantUsername,
          merchantPassword: data.merchantPassword
            ? data.merchantPassword
            : original!.merchantPassword,
          merchantIntegrationNumber: data.merchantIntegrationNumber,
          merchantAPIKey: data.merchantAPIKey,
          precedence: parseInt(data.precedence),
          autoSendOrder: data.autoSendOrder,
          isPinRequired: data.isPinRequired,
          needAssistance: data.needAssistance,
          spaBooking: data.spaBooking,
          showerBooking: data.showerBooking,
          cigarMenu: data.cigarMenu,
          viewOnly: data.viewOnly,
          multiLanguages: multiLanguages,
          WaiterNotificationOption: data.WaiterNotificationOption
            ? data.WaiterNotificationOption
            : original!.WaiterNotificationOption,
          _version: original._version,
        };

        const concept: any = await API.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        showConfirm(`${singleName} has been updated successfully`);
        return concept.data.updateConcept;
      } else {
        let newReceiveWaiters: any = [];
        if (original!.receiveWaiters) {
          newReceiveWaiters = [...original!.receiveWaiters];
        }
        if (newReceiveWaiters && newReceiveWaiter) {
          newReceiveWaiters.push(newReceiveWaiter);
        }
        if (newReceiveWaiters && delteWaiterID) {
          newReceiveWaiters = newReceiveWaiters.filter((stringWaiter: any) => {
            let objectWaiter = JSON.parse(stringWaiter);
            return objectWaiter.id !== delteWaiterID;
          });
        }

        const updateInput: UpdateConceptInput = {
          id: original.id,
          name: original!.name,
          merchantID: original!.merchantID,
          merchantUsername: original!.merchantUsername,
          merchantPassword: original!.merchantPassword,
          merchantIntegrationNumber: original!.merchantIntegrationNumber,
          merchantAPIKey: original!.merchantAPIKey,
          precedence: original!.precedence,
          isPinRequired: original!.isPinRequired,
          needAssistance: original!.needAssistance,
          spaBooking: original!.spaBooking,
          showerBooking: original!.showerBooking,
          cigarMenu: original!.cigarMenu,
          viewOnly: original!.viewOnly,
          WaiterNotificationOption: original!.WaiterNotificationOption,
          receiveWaiters: newReceiveWaiters,
          // multiLanguages:original!.multiLanguages ?? "",
          _version: original._version,
        };

        await API.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ConceptGetVariables) {
    try {
      const { id, listing } = params;

      const original: any = await get(params);

      if (original) {
        const updateInput: UpdateConceptInput = {
          id: original.id,
          deleted: "1",
          _version: original._version,
        };

        await API.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        dispatch(
          setListing(listing.filter((resource: any) => resource.id !== id))
        );
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ConceptBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      await API.graphql<Concept>({
        query: deleteConcept,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function exportAll(params: ConceptListingVariables) {
    const data = await fetch(params);
    return data;
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: ConceptGetVariables) => {
    const { id, listing } = params;

    if (listing && listing.length > 0) {
      const model = listing.find((model: Concept) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  function options(listing: Concept[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },

    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = Concept as any;
  api[`${listingName}CreateSubscription`] = onCreateConcept;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Concept[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };

  api[`${listingName}ChangeSelected`] = (conceptID: string) => {
    dispatch(setSelected(conceptID));

    localStorage.setItem(LOCAL_STORAGE.SELECTED_CONCEPT, conceptID);
  };
  api[`${listingName}SetBranches`] = (branches: any) =>
    dispatch(setBranches(branches));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };

  return api;
};

export default useResource;
