import {
  CreateVariables,
  NotificationBulkTrashVariables,
  NotificationUpdateVariables,
  UserGetVariables,
  NotificationAutoSendVariables,
  EmailSmsVariables,
} from "./../models/app";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/notifications";
import { HeadCell } from "../models/dataTable";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import {
  Booking,
  Concept,
  Notification,
  ReservationStatus,
  User,
  TimeSlot,
} from "../models";
import useApp from "./useApp";
import useConcept from "./useConcept";
import useStatus from "./useStatus";
import useUser from "./useUser";
import useTimeSlot from "./useTimeSlot";
import {
  NotificationGetVariables,
  NotificationListingVariables,
} from "../models/app";
import { CreateNotificationInput } from "../models/GQL_API";
import { listNotifications } from "../graphql/queries";
import { onCreateNotification } from "../graphql/subscriptions";
import { getUAEDateTimeFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError, showWarning } = useApp();
  const { conceptsGetName, conceptsGetOnline } = useConcept(
    "concepts",
    "concept"
  );
  const { statusesGetName, statusesGetOnline } = useStatus(
    "statuses",
    "status"
  );
  const { timeSlotsGet } = useTimeSlot("timeSlots", "timeSlot");
  const { guestsGet } = useUser("guests", "guest");
  const conceptsListing: Concept[] = useSelector(
    (state: any) => state.concepts.listing
  );
  const statusesListing = useSelector((state: any) => state.statuses.listing);
  const timeSlotsListing = useSelector((state: any) => state.timeSlots.listing);
  const session = useSelector((state: any) => state.app.session);

  async function fetch(params: NotificationListingVariables) {
    const { accountID, conceptID, searchText, startIndex, limit, status } =
      params;

    try {
      const listing = await DataStore.query(
        Notification as any,
        (model: any) => {
          model
            .accountID("eq", accountID)
            .conceptID("eq", conceptID)
            .deleted("eq", "0");

          if (status) model.name("eq", status.toLowerCase());

          if (searchText.length > 0) {
            model.or((model: any) => {
              model.name("contains", searchText.toLowerCase());
              model.channel("contains", searchText.toLowerCase());
              model.from("contains", searchText.toLowerCase());
            });
          }

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );
      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function fetchOnline() {
    const filter: any = { deleted: { eq: "0" } };
    const limit: any = 1000;

    try {
      const listing: any = await API.graphql({
        query: listNotifications,
        variables: {
          limit,
          filter,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return listing.data.listNotifications.items;
    } catch (err) {
      throw err;
    }
  }

  async function fireNotifications(params: NotificationListingVariables) {
    const { booking, guests, concepts, statuses, slots, channel } = params;

    if (!booking) {
      const error = new Error("Cannot send notification without booking");
      return showError(error);
    }
    // if (!status) const error = new Error("Cannot send notification without status");
    if (!guests) {
      const error = new Error("Cannot send notification without users");
      return showError(error);
    }
    if (!concepts) {
      const error = new Error("Cannot send notification without concepts");
      return showError(error);
    }
    if (!statuses) {
      const error = new Error("Cannot send notification without statuses");
      return showError(error);
    }
    if (!slots) {
      const error = new Error("Cannot send notification without slots");
      return showError(error);
    }

    try {
      let status = statusesGetName({
        id: booking.statusID,
        listing: statuses,
      }).toLowerCase();
      const paramsGetUser: UserGetVariables = {
        id: booking.mainGuest,
        listing: guests,
      };
      let mainGuest: User = await guestsGet(paramsGetUser);
      let notifications = await fetch(params);

      if (notifications && notifications.length > 0) {
        for (let notification of notifications) {
          if (notification.channel === channel) {
            if (notification.enabled) {
              await sendNotification(
                notification,
                booking,
                mainGuest,
                concepts,
                statuses,
                slots
              );
            } else {
              showWarning(
                `Notification ${notification.name} is disabled for status ${status}`
              );
            }
          }
        }
      } else {
        showWarning("No notifications found for this status in this concept");
      }
    } catch (err: Error | any) {
      showError(err.message || err);
    }
  }

  async function autoFireNotifications(params: NotificationListingVariables) {
    const { booking, guests, concepts, statuses, slots, channel } = params;

    if (!booking) {
      const error = new Error("Cannot send notification without booking");
      return showError(error);
    }
    //if (!status) const error = new Error("Cannot send notification without status");
    if (!guests) {
      const error = new Error("Cannot send notification without guests");
      return showError(error);
    }
    if (!concepts) {
      const error = new Error("Cannot send notification without concepts");
      return showError(error);
    }
    if (!statuses) {
      const error = new Error("Cannot send notification without statuses");
      return showError(error);
    }
    if (!slots) {
      const error = new Error("Cannot send notification without slots");
      return showError(error);
    }

    try {
      const paramsGetUser: UserGetVariables = {
        id: booking.mainGuest,
        listing: guests,
      };

      let status = statusesGetName({
        id: booking.statusID,
        listing: statuses,
      }).toLowerCase();
      let mainGuest: User = await guestsGet(paramsGetUser);
      let notifications = await fetch(params);

      if (notifications && notifications.length > 0) {
        for (let notification of notifications) {
          if (notification.channel === channel) {
            if (notification.enabled && notification.autoSend) {
              await sendNotification(
                notification,
                booking,
                mainGuest,
                concepts,
                statuses,
                slots
              );
            } else {
              showWarning(
                `AutoSend is disabled for notification ${notification.name} for status ${status}`
              );
            }
          }
        }
      } else {
        showWarning("No notifications found for this status in this concept");
      }
    } catch (err: Error | any) {
      showError(err.message || err);
    }
  }

  async function sendNotification(
    notification: any,
    booking: Booking,
    mainGuest: User,
    concepts: Concept[],
    statuses: ReservationStatus[],
    slots: TimeSlot[]
  ) {
    // Email notification
    if (notification.channel === "email") {
      if (mainGuest && mainGuest.email) {
        await sendEmail(booking, mainGuest.email, concepts, statuses, slots);
        showConfirm("Email send successfully");
      } else {
        showWarning(`No email address found for ${mainGuest.name}`);
      }
    }
    // SMS notification
    if (notification.channel === "sms") {
      if (mainGuest && mainGuest.phone_number) {
        let conceptName = conceptsGetName({
          id: booking.conceptID,
          listing: conceptsListing,
        });
        if (!mainGuest.email || mainGuest.email === "") {
          //if no email in resend sms.send sms with Confirm email instead
          await sendEmailSMS({ booking, conceptName });
        } else {
          await sendSMS(booking, mainGuest.phone_number, conceptName);
        }
      } else {
        showWarning(`No phone number found for ${mainGuest.name}`);
      }
    }
  }

  function createHashLink(booking: Booking) {
    return `${window.origin}/reservations/${booking.id}`;
  }

  async function sendEmail(
    booking: Booking,
    email: string,
    conceptsListing: Concept[],
    statuses: ReservationStatus[],
    slots: TimeSlot[]
  ) {
    let bookingLink = createHashLink(booking);
    let customerName = booking.customerName;
    let customerEmail = email;
    let reservationDate = booking.date;
    let reservationTime = "";
    for (let slot of booking?.timeSlots!) {
      let slotName = await timeSlotsGet({
        id: slot,
        listing: slots,
      });
      let from = slotName.name.split("-")[0];
      let to = slotName.name.split("-")[1];
      reservationTime += "from " + from + "to " + to + " ";
    }
    let numOfGuests = booking.accompaniedCount;

    // get concept name online
    let conceptName = await conceptsGetOnline(booking.conceptID, session);
    let statusesName = await statusesGetOnline(booking.statusID, session);
    let templateName = `${conceptName.name}${statusesName.name}`;

    switch (templateName) {
      case "Test ConceptReserved":
        templateName = "ParkConfirmed";
        break;
    }

    const SOURCE_EMAIL = "no-reply@anyware.software";
    const QUERY_PARAMS = `?customer_name=${customerName}&customer_email=${customerEmail}&reservation_date=${reservationDate}&reservation_time=${reservationTime}&number_of_guests=${numOfGuests}&source_email=${SOURCE_EMAIL}&template_name=${templateName}&booking_link=${bookingLink}`;

    // (Amplify Env: EtihadAirways - Dev)
    const SEND_MAIL_URL = `https://rtoskdbki6usx3myvymcq3gply0jkglk.lambda-url.us-east-2.on.aws/${QUERY_PARAMS}`;

    let mailSent = false;
    try {
      await window
        .fetch(SEND_MAIL_URL)
        .then((response) => {
          response.status === 200 ? (mailSent = true) : (mailSent = false);
        })
        .catch((err) => {
          mailSent = false;
        });
      if (mailSent) console.log("email", { booking, email, templateName });
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: NotificationGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Notification) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  async function get(params: NotificationGetVariables) {
    const { id, listing } = params;

    try {
      const single: Notification | undefined =
        listing.length === 0
          ? await DataStore.query(Notification as any, id)
          : listing.find((model: any) => model.id === id);

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateNotificationInput = {
        accountID: data.accountID,
        conceptID: data.conceptID,
        name: data.name.toLowerCase().trim(),
        deleted: "0",
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.channel) createInput.channel = data.channel.toLowerCase();
      if (data.message) createInput.message = data.message;
      if (data.from) createInput.from = data.from.toLowerCase();
      if (data.enabled) createInput.enabled = data.enabled;
      if (data.autoSend) createInput.autoSend = data.autoSend;

      const model: Notification = await DataStore.save(
        new Notification(createInput as any)
      );
      showConfirm(`New ${singleName} has been created successfully`);
      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: NotificationUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      await DataStore.save(
        Notification.copyOf(original!, (updated) => {
          updated.name =
            data.name !== undefined ? data.name.toLowerCase() : original!.name;
          updated.channel =
            data.channel !== undefined
              ? data.channel.toLowerCase()
              : original!.channel;
          updated.message =
            data.message !== undefined ? data.message : original!.message;
          updated.from =
            data.from !== undefined ? data.from.toLowerCase() : original!.from;
          updated.enabled =
            data.enabled !== undefined ? data.enabled : original!.enabled;
          updated.autoSend =
            data.autoSend !== undefined ? data.autoSend : original!.autoSend;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: NotificationGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Notification.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: NotificationBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: NotificationGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function sendSMS(booking: Booking, phone: string, conceptName: string) {
    let bookingLink = createHashLink(booking);
    const message = `Hello ${booking.customerName}, Thank you for your reservation. Please confirm your reservation from the following link: ${bookingLink}`;

    // (Staging Env)
    const SEND_SMS_URL_STAGING = `https://fyv5erlwl2ejyzomftpcmmh7qa0bijdh.lambda-url.us-east-2.on.aws/?phone=${phone}&message=${message}&conceptName=${conceptName}`;

    // (Amplify Env: EtihadAirways - Dev)
    const SEND_SMS_URL = `https://nlh4cvbtqanvq3pjv7wdsvc6my0sglsq.lambda-url.us-east-2.on.aws/?phone=${phone}&message=${message}&conceptName=${conceptName}`;

    console.log("inside send SMS");
    try {
      await window
        .fetch(SEND_SMS_URL)
        .then((response: any) => {
          console.log("response", response);
          if (response.status === 200) {
            showConfirm("SMS send successfully");
            console.log("sms sent");
          }
        })
        .catch((err) => {
          showError(
            typeof err.message === "string"
              ? err.message
              : "error occurred while sending SMS"
          );
        });
    } catch (err) {
      // console.log(err);
    }
  }

  async function sendEmailSMS(params: EmailSmsVariables) {
    const { booking, conceptName } = params;
    // const bookingLink = `${window.origin}/email/${booking.id}`
    const bookingLink = `${window.origin}/email/${booking.id}/${booking.conceptID}`;

    const message = `Hello ${booking.customerName}, Thank you for your reservation at ${conceptName}. Please confirm your reservation from the following link:${bookingLink}`;

    // (Staging Env)
    const SEND_SMS_URL_Staging = `https://fyv5erlwl2ejyzomftpcmmh7qa0bijdh.lambda-url.us-east-2.on.aws/?phone=${booking.customerPhone}&message=${message}&conceptName=${conceptName}`;

    // (Amplify Env: EtihadAirways - Dev)
    const SEND_SMS_URL = `https://nlh4cvbtqanvq3pjv7wdsvc6my0sglsq.lambda-url.us-east-2.on.aws//?phone=${booking.customerPhone}&message=${message}&conceptName=${conceptName}`;

    try {
      await window
        .fetch(SEND_SMS_URL)
        .then((response: any) => {
          if (response.status === 200) {
            showConfirm("SMS send successfully");
            console.log("sms sent");
          }
        })
        .catch((err) => {
          showError(
            typeof err.message === "string"
              ? err.message
              : "error occurred while sending SMS"
          );
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  async function checkAutoSendStatus(params: NotificationAutoSendVariables) {
    let { booking, guest, conceptID, isDirect = true } = params;
    const concept: Concept | undefined = await conceptsGetOnline(
      conceptID,
      session
    );
    // const concept: Concept | undefined = conceptsListing.find(
    //   (model: Concept) => model.id === conceptID
    // );

    if (!concept) return showError("Invalid booking concept");

    try {
      const userNotificationsModules: any | undefined = await fetchOnline();
      let ConceptNotificationsList: any[] = [];

      for (let i = 0; i < userNotificationsModules.length; i++) {
        if (userNotificationsModules[i].conceptID === booking.conceptID)
          ConceptNotificationsList = [
            ...ConceptNotificationsList,
            userNotificationsModules[i],
          ];
      }

      if (!ConceptNotificationsList || ConceptNotificationsList.length < 1)
        return showError("Concept is not existing");
      let smsChannel = false;
      let emailChannel = false;
      for (let item of ConceptNotificationsList) {
        if (!item.autoSend) {
          continue;
        }

        if (item.channel === "sms") {
          smsChannel = true;
          emailChannel = false;
          // await sendSMS(booking, guest.phone_number);
        } else if (item.channel === "email") {
          emailChannel = true;
          smsChannel = false;
        }

        // send sms only if sms auto send is on.** && smsChannel **
        // to verify email
        if (emailChannel) {
          const emailSmsParams: EmailSmsVariables = {
            booking: booking,
            conceptName: concept.name,
          };

          if (!guest.email || guest.email === "") {
            sendEmailSMS(emailSmsParams);
            return;
          } else if (guest.email && guest.email !== "") {
            await sendEmail(
              booking,
              guest.email,
              conceptsListing,
              statusesListing,
              timeSlotsListing
            );
          }
        }

        if (smsChannel && isDirect && (guest.email || guest.email !== "")) {
          await sendSMS(booking, guest.phone_number, concept.name);
        }
      }
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "channel",
      numeric: false,
      disablePadding: false,
      label: "Channel",
    },
    {
      id: "enabled",
      numeric: false,
      disablePadding: false,
      label: "Enabled",
    },
    {
      id: "autoSend",
      numeric: false,
      disablePadding: false,
      label: "Auto Send",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = [
    "name",
    "channel",
    "enabled",
    "autoSend",
  ];

  const api: any = {};

  api[`${listingName}Model`] = Notification as any;
  api[`${listingName}CreateSubscription`] = onCreateNotification;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Fire`] = fireNotifications;
  api[`${listingName}AutoFire`] = autoFireNotifications;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}CheckAutoSendStatus`] = checkAutoSendStatus;
  api[`${listingName}ChangeListing`] = (listing: Notification[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
