import {
  CreateVariables,
  FeatureBulkTrashVariables,
  FeatureGetVariables,
  FeatureUpdateVariables,
  ListingVariables,
  Option,
} from "./../models/app";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLimit,
  nextAction,
  setListing,
  setListingAll,
  setNextToken,
  setPreviousToken,
  setSelected,
} from "../store/ducks/feature";
import { Feature } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { CreateFeatureInput, UpdateFeatureInput } from "../models/GQL_API";
import {
  createFeature,
  deleteFeature,
  updateFeature,
} from "../graphql/mutations";
import { getFeature, listFeatures } from "../graphql/queries";
import { onCreateFeature } from "../graphql/subscriptions";
import { getUAEDateTimeFormatted } from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const storedLimit = useSelector(
    (state: any) => state[`${listingName}`]["limit"]
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  const previousTokens = useSelector(
    (state: any) => state[`${listingName}`]["previousTokens"]
  );

  async function fetch(params: ListingVariables) {
    const { searchText, startIndex, limit, moveForward } = params;

    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText.length > 0) {
        let or = [
          { name: { contains: searchText.toLowerCase() } },
          { slug: { contains: searchText.toLowerCase() } },
        ];

        filter.or = or;
      }

      // Pagination
      let requestLimit = limit ? limit : storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;
      let listing: any[] = [];

      let itemsList: any = null;
      let partialList: any[] = [];
      let currentNextToken;

      // Clear pagination data
      if (limit !== storedLimit || startIndex === 0) {
        requestLimit = limit;
        requestToken = null;
        requestPreviousTokens = [];

        dispatch(changeLimit(limit));
      }

      // Get token from previous tokens
      if (!moveForward) {
        const updatedPreviousTokens = [...requestPreviousTokens];
        updatedPreviousTokens.pop();

        requestToken =
          updatedPreviousTokens.length >= 2
            ? updatedPreviousTokens[updatedPreviousTokens.length - 2]
            : null;
        dispatch(
          setNextToken(updatedPreviousTokens[updatedPreviousTokens.length - 1])
        );
        dispatch(setPreviousToken(updatedPreviousTokens));
      }

      do {
        // fetch admin status list
        itemsList = await API.graphql<Feature>({
          query: listFeatures,
          variables: { filter, limit: requestLimit, nextToken: requestToken },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        // Filter list using selected concept
        partialList = itemsList.data.listFeatures.items;
        listing = [...listing, ...partialList];

        if (moveForward) {
          currentNextToken = itemsList?.data?.listFeatures?.nextToken;
          dispatch(nextAction(currentNextToken, requestPreviousTokens));
        }
        requestToken = currentNextToken;
      } while (
        (listing.length < limit || listing.length / limit < 0) &&
        currentNextToken !== null
      );

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: ListingVariables) {
    const { searchText, limit } = params;

    try {
      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (searchText.length > 0) {
        let or = [
          { name: { contains: searchText.toLowerCase() } },
          { slug: { contains: searchText.toLowerCase() } },
        ];

        filter.or = or;
      }

      let listing = [];
      let featureList: any = null;
      try {
        featureList = await API.graphql<Feature>({
          query: listFeatures,
          variables: { filter, limit, nextToken: nextToken },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
      } catch (err: Error | any) {
        featureList = await API.graphql<Feature>({
          query: listFeatures,
          variables: { filter, limit, nextToken: nextToken },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      listing = featureList.data.listFeatures.items;

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: FeatureGetVariables) {
    const { id, listing } = params;

    try {
      let single: Feature | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Feature>({
          query: getFeature,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getFeature;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateFeatureInput = {
        precedence: data.precedence,
        name: data.name.toLowerCase().trim(),
        slug: data.slug,
        deleted: "0",
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.icon) createInput.icon = data.icon;
      if (data.parent) createInput.parent = data.parent;
      if (data.private) createInput.private = data.private;

      await API.graphql<Feature>({
        query: createFeature,
        variables: { input: createInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: FeatureUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateFeatureInput = {
        id: original.id,
        precedence: data.precedence ? data.precedence : original!.precedence,
        name: data.name ? data.name : original!.name,
        slug: data.slug ? data.slug : original!.slug,
        icon: data.icon ? data.icon : original!.icon,
        parent: data.parent ? data.parent : original!.parent,
        private: data.private ? data.private : original!.private,
      };

      await API.graphql<Feature>({
        query: updateFeature,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: FeatureGetVariables) {
    try {
      const original = await get(params);

      if (original) {
        const updateInput: UpdateFeatureInput = {
          id: original.id,
          deleted: "1",
        };

        await API.graphql<Feature>({
          query: updateFeature,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: FeatureBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(
      setListing(listing.filter((resource: any) => !ids.has(resource.id)))
    );

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: FeatureGetVariables) {
    const { id, listing } = params;
    try {
      await API.graphql<Feature>({
        query: deleteFeature,
        variables: { id: id },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  function options(listing: Feature[]) {
    const options: Option[] = [{ label: "none", value: "-1" }];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = Feature as any;
  api[`${listingName}CreateSubscription`] = onCreateFeature;

  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Feature[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeListingAll`] = (listing: Feature[]) =>
    dispatch(setListingAll(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
