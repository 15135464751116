import { CreatePriceInput, UpdatePriceInput } from "./../models/GQL_API";
import {
  GetVariables,
  ListingByConceptVariables,
  Option,
  PriceBulkTrashVariables,
} from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { MenuItem, Price } from "../models";
import { createPrice, updatePrice } from "../graphql/mutations";
import { getPrice, listPrices } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import { setListing } from "../store/ducks/price";
import { getDateFormatted, getUAEDateTimeFormatted } from "../helpers/utils";
import { onCreatePrice } from "../graphql/subscriptions";
import { getTimeInUAE } from "../helpers/utils";
import useGeneralPagination from "./useGeneralPagination";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const pricesListing = useSelector((state: any) => state.prices.listing);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);
  const { generalFetchAllByConceptID } = useGeneralPagination(
    listingName,
    singleName
  );

  async function fetch(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit } = params;
      const filter: any = {
        deleted: { eq: "0" },
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
      };

      if (searchText && searchText.length > 0)
        filter.name = { contains: searchText.toLowerCase() };

      if (
        pricesListing.length === 0 ||
        selectedConcept !== pricesListing[0].conceptID
      ) {
        const firistListing: any = await API.graphql<GraphQLQuery<Price>>({
          query: listPrices,
          variables: { filter, limit: limit ?? 1000 },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        let nextToken = firistListing.data.listPrices.nextToken;
        let allPrices = firistListing.data.listPrices.items;

        while (nextToken && nextToken.length > 0) {
          const listing: any = await API.graphql<GraphQLQuery<Price>>({
            query: listPrices,
            variables: { filter, limit: limit ?? 1000 },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          });

          allPrices = allPrices.concat(listing.data.listPrices.items);
          nextToken = listing.data.listPrices.nextToken;
        }
        return allPrices;
      } else {
        return pricesListing;
      }
    } catch (err) {
      showError(err);
    }
  }

  async function fetchAllConceptID(params: ListingByConceptVariables) {
    try {
      const listing = await generalFetchAllByConceptID(
        params,
        "priceByConceptID"
      );
      return listing;
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const price: any = await API.graphql({
        query: getPrice,
        variables: { id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      return price.data.getPrice;
    } catch (err) {
      throw err;
    }
  }

  async function getPrices(single: MenuItem) {
    try {
      const prices: Price[] = [];
      if (!single) return prices;

      if (
        single.prices === null ||
        (single.prices && single.prices.length === 0)
      )
        return prices;

      if (single.prices && single.prices.length > 0) {
        for (let priceID of single.prices) {
          if (priceID) {
            let price = await get({ id: priceID });
            if (price && price.deleted === "0") prices.push(price);
          }
        }

        return prices;
      }
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    try {
      if (!data.name) throw new Error("Name is required");
      if (!data.price) throw new Error("Price is required");

      const createInput: CreatePriceInput = {
        name: data.name ? data.name.toLowerCase().trim() : "",
        price: data.price ? data.price : "",
        symphonyID: data.symphonyID ? data.symphonyID : "",
        conceptID: data.conceptID,
        deleted: "0",
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      const Price: any = await API.graphql<GraphQLQuery<Price>>({
        query: createPrice,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return Price.data.createPrice;
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function update(params: any, session: any) {
    try {
      const { data } = params;
      let original = await get(params);
      const updateInput: UpdatePriceInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase().trim() : original.name,
        price: data.price ? data.price : original.price,
        symphonyID: data.symphonyID ? data.symphonyID : original.symphonyID,
        _version: original._version,
      };

      const Price: any = await API.graphql<GraphQLQuery<Price>>({
        query: updatePrice,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return Price.data.updatePrice;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      const updateInput: UpdatePriceInput = {
        id: original.id,
        deleted: "1",

        _version: original._version,
      };

      await API.graphql<GraphQLQuery<Price>>({
        query: updatePrice,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      for (let i = 0; i < pricesListing.length; i++) {
        if (pricesListing[i].id === original.id) {
          pricesListing.splice(i, 1);
          dispatch(setListing(pricesListing));
          break;
        }
      }

      // for (let i = 0; i < paginationListing.length; i++) {
      //   if (paginationListing[i].id === original.id) {
      //     paginationListing.splice(i, 1);
      //     dispatch(setPagination(paginationListing));
      //     break;
      //   }
      // }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: PriceBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function trashPrice(single: MenuItem, priceId: string) {
    try {
      const prices: (string | null)[] =
        single && single.prices ? [...single.prices] : [];

      let price = prices.indexOf(priceId);

      if (price > -1) prices.splice(price, 1);

      //   await update({ id: single.id, data: { prices } }, true);
      await trash({ id: priceId });
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  function options(listing: Price[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "Price",
    },
    {
      id: "symphonyID",
      numeric: false,
      disablePadding: false,
      label: "Simphony ID",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];
  const dataCells: readonly string[] = ["name", "price", "symphonyID"];

  const api: any = {};

  api[`${listingName}Model`] = Price as any;
  api[`${listingName}CreateSubscription`] = onCreatePrice;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAllConceptID;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetPrices`] = getPrices;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}TrashPrice`] = trashPrice;

  api[`${listingName}ChangeListing`] = (listing: Price[]) =>
    dispatch(setListing(listing));

  return api;
};

export default useResource;
