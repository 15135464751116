import { API, SortDirection } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import { DataStore } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setListing, setSelected } from "../store/ducks/userConcepts";
import { EagerUserConcepts, UserConcepts } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import {
  UserConceptsGetVariables,
  CreateVariables,
  UserConceptsBulkTrashVariables,
  UserConceptsUpdateVariables,
  Option,
  GetVariables,
  UserConceptsListingVariables,
} from "../models/app";
import { CreateUserConceptsInput } from "../models/GQL_API";
import { getTimeInUAE, getUAEDateTimeFormatted } from "../helpers/utils";
import { getUserConcepts, listUserConcepts } from "../graphql/queries";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const session = useSelector((state: any) => state.app.session);

  // Need Test - Fetch all user concepts
  async function fetchOnline(
    params: UserConceptsListingVariables,
    isAuth: boolean
  ) {
    const { searchText, startIndex, limit, userID } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (userID) filter.createdByID = { eq: userID };
      if (searchText.length > 0)
        filter.defaultConcept = { contains: searchText.toLowerCase() };

      console.log({
        session,
        isAuth,
      });
      const conceptListing: any = await API.graphql({
        query: listUserConcepts,
        variables: {
          filter,
          limit: limit || 1000,
        },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const listing: any = conceptListing.data.listUserConcepts.items;
      // const currentNextToken = conceptListing.data.listUserConcepts.nextToken;
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  // Need Test
  async function getOnline(params: GetVariables) {
    try {
      const { id } = params;

      console.log({
        userConceptID: id,
      });

      const user: any = await API.graphql({
        query: getUserConcepts,
        variables: { id: id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const userConcept = user.data.getUserConcepts;
      console.log({
        userConcept,
      });
      return userConcept;
    } catch (err) {
      showError(err);
    }
  }

  async function fetch(params: UserConceptsListingVariables) {
    const { searchText, startIndex, limit, userID } = params;

    try {
      const listing = await DataStore.query(
        UserConcepts as any,
        (model: any) => {
          model.deleted("eq", "0");

          if (userID) model.createdByID("eq", userID);

          if (searchText.length > 0)
            model.defaultConcept("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: GetVariables) {
    const { id } = params;

    try {
      const single: UserConcepts | undefined = await DataStore.query(
        UserConcepts as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    const listingParams: UserConceptsListingVariables = {
      startIndex: 0,
      limit: 1000,
      searchText: "",
      userID: userID,
    };

    const existed = await fetch(listingParams);

    if (existed!.length > 0 && existed) {
      const params: UserConceptsUpdateVariables = {
        id: existed[0].id,
        data: { concepts: data.concepts, conceptsRoles: data.conceptsRoles },
      };
      await update(params);
      return;
    }

    try {
      const createInput: CreateUserConceptsInput = {
        defaultConcept: data.defaultConcept ? data.defaultConcept : "",
        concepts: data.concepts ? data.concepts : [],
        conceptsRoles: data.conceptsRoles ? data.conceptsRoles : [],
        deleted: "0",
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
      };

      const model = await DataStore.save(new UserConcepts(createInput as any));

      return model;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: UserConceptsUpdateVariables) {
    const { id, data } = params;

    try {
      const original = await get({ id });

      await DataStore.save(
        UserConcepts.copyOf(original!, (updated) => {
          updated.defaultConcept = data.defaultConcept
            ? data.defaultConcept
            : original!.defaultConcept;
          updated.concepts = data.concepts ? data.concepts : original!.concepts;
          updated.conceptsRoles = data.conceptsRoles
            ? data.conceptsRoles
            : original!.conceptsRoles;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        UserConcepts.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: UserConceptsBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: UserConceptsGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: UserConcepts[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.id, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = UserConcepts as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: UserConcepts[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (selected: EagerUserConcepts | null) =>
    dispatch(setSelected(selected));

  return api;
};

export default useResource;
