import { Order } from "../models/order";
import {
  AccountDomain,
  LOCAL_STORAGE,
  Orders,
  Pages,
} from "../constants/enums";
import { HeadCell } from "../models/dataTable";
import * as XLSX from "xlsx";

import etihadLogo from "../assets/images/etihad-logo.png";
import { Account } from "../models";

export function isMobile() {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function descendingComparatorLowerCase<
  T extends Record<keyof T, string | number>
>(a: T, b: T, orderBy: keyof T) {
  const aVal = String(a[orderBy]).toLowerCase();
  const bVal = String(b[orderBy]).toLowerCase();
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

/**
 * Capitalize Sentence

 * @param string : string
 *
 * @returns string
 */
export function capitalizeSentence(string: string): string {
  let result: string = "";

  const words: string[] = string.split(" ");

  for (let word of words) {
    result +=
      result.length === 0
        ? capitalizeFirstLetter(word)
        : " " + capitalizeFirstLetter(word);
  }

  return result;
}

/**
 * Convert string hours to it's equivalent in numbers
 *
 * @param hours hours: string
 * @param format format: string
 *
 * @returns number
 */
export const hoursStringToNumber = (hours: string, format: string): number => {
  const time = hours.split(":")[0];

  return format === "PM"
    ? parseInt(time) + 12 === 24
      ? 12
      : parseInt(time) + 12
    : parseInt(time) === 12
    ? 0
    : parseInt(time);
};

/**
 * Compare giver hour in number to current locale hour
 *
 * @param hour hour: number
 *
 * @returns number
 */
export const compareHoursToCurrent = (hour: number): number => {
  const d = new Date();
  let currentHour = d.getHours();

  return hour - currentHour;
};

/**
 * Capitalize first letter of a given string
 *
 * @param string string: string
 *
 * @returns string
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === Orders.DSC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function getComparatorLowerCase<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === Orders.DSC
    ? (a, b) => descendingComparatorLowerCase(a, b, orderBy)
    : (a, b) => -descendingComparatorLowerCase(a, b, orderBy);
}

export function stableSort(
  array: any[],
  comparator: any,
  isSortByNameAsNumber: boolean = false
) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (isSortByNameAsNumber) {
      return parseInt(a[0].name) < parseInt(b[0].name) ? -1 : 1;
    } else {
      if (order !== 0) return order;
      return a[1] - b[1];
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

export function stripHtml(html: string) {
  return html.replace(/(<([^>]+)>)/gi, "");
}

export function exportXLSX(
  slug: string,
  headCells: readonly HeadCell[],
  data: any[],
  rowKeys: readonly string[],
  selected?: Set<string>,
  filename?: string,
  reportName?: string,
  businessDate?: string,
  concepts?: string,
  shifts?: string
) {
  const heads: any = [];
  const rows: any = [];

  // Report Header
  if (reportName) {
    rows.push(["Report:", reportName]);
  }

  rows.push(["Business Date:", businessDate ? businessDate : "All"]);
  rows.push(["Concept:", concepts ? concepts : "All"]);
  rows.push(["Shifts:", shifts ? shifts : "All"]);

  rows.push([]); // Add an empty row for spacing

  let exportData = data;

  if (selected) {
    exportData = data.filter((row: any) => selected.has(row.id));
  }

  let headLen =
    headCells[headCells.length - 1].label === "actions"
      ? headCells.length - 1
      : headCells.length;
  for (let i = 0; i < headLen; i++) {
    // csv format doesn't accept empty symbols
    const label =
      headCells[i].label === "# of Guests"
        ? "Number of Guests"
        : headCells[i].label;

    heads.push(label);
  }

  rows.push(heads);

  if (slug === Pages.BOOKINGS) {
    for (let row of exportData) {
      const rowItems: any = [];

      if (row.conceptName) {
        rowItems.push(row.conceptName ? row.conceptName : "");
      }

      // rowItems.push(row.timeSlotName ? row.timeSlotName : "");
      rowItems.push(
        row.slotNames ? mergeListOfStringsByDash(row.slotNames) : ""
      );
      rowItems.push(
        row.customerName ? capitalizeFirstLetter(row.customerName) : ""
      );
      rowItems.push(row.customerGroup ? row.customerGroup : "");
      rowItems.push(row.customerPhone ? row.customerPhone : "");
      rowItems.push(row.accompaniedCount ? row.accompaniedCount : "");
      rowItems.push(row.tableName ? row.tableName : "");
      rowItems.push(row.statusName ? row.statusName : "");
      rowItems.push(
        isNaN(row.customerPhone) ? "" : row.paymentStatus ? "Paid" : "Pending"
      );
      // rowItems.push(row.lastComment ? stripHtml(row.lastComment) : "");
      rowItems.push(
        row.commentsTexts ? mergeListOfStringsByDash(row.commentsTexts) : ""
      );
      rowItems.push(row.date ? row.date : "");
      rowItems.push(row.createdBy ? row.createdBy.name : "Admin");
      rowItems.push(row.createdAt ? row.createdAt.split(",")[0] : "");

      rows.push(rowItems);
    }
  } else if (slug === Pages.TRANSACTIONS_REPORT) {
  } else {
    for (let row of exportData) {
      const rowItems: any = [];

      for (let key of rowKeys) {
        rowItems.push(row[key]);
      }

      if (slug === Pages.GUESTS) {
        rowItems.push(row.groupName ? row.groupName : "");
        rowItems.push(
          row.interestsName ? mergeListOfStringsByDash(row.interestsName) : ""
        );
      }

      rowItems.push(row.createdByName ? row.createdByName : "Admin");
      if (row.createdAt) rowItems.push(row.createdAt.split(",")[0]);

      rows.push(rowItems);
    }
  }

  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { type: "base64" });

  const encodedUri = encodeURI(rawExcel);
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      encodedUri
  );
  link.setAttribute("download", `${filename ? filename : slug}.xlsx`);
  document.body.appendChild(link); // Required for FF

  link.click();
}

export function randomStr(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function oldDateChecker(date: any) {
  if (!date) {
    return false;
  }
  let givenDate = new Date(date);
  givenDate.setHours(0, 0, 0, 0);

  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  let checker = false;
  if (givenDate.getTime() < currentDate.getTime()) {
    checker = true;
  }
  return checker;
}

// return full date into string
export function isoFullDateFormated(isoFullDate: string) {
  // insert format "2023-01-15:15:52";
  const isoNewDate = new Date(isoFullDate);
  return isoNewDate;
}

export const formatAMPM = (isoDate: string) => {
  const date = new Date(isoDate);
  let hours: string | number = date.getHours();
  let minutes: string | number = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const strTime = `${hours}:${minutes} ${ampm}`;

  return strTime;
};

export function getWeekDays(locale: string) {
  const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  const weekDays = [];

  for (let i = 0; i < 7; i++) {
    weekDays.push(baseDate.toLocaleDateString(locale, { weekday: "long" }));
    baseDate.setDate(baseDate.getDate() + 1);
  }

  return weekDays;
}

export function getDayName(dateStr: string, locale: string) {
  const date = new Date(dateStr);
  return date.toLocaleDateString(locale, { weekday: "short" });
}

export function getDayNameLong(dateStr: string, locale: string) {
  const date = new Date(dateStr);
  return date.toLocaleDateString(locale, { weekday: "long" });
}

export function getDayNumber(dateStr: string, locale: string) {
  const date = new Date(dateStr);
  return date.getDate();
}

export function getMonthName(dateStr: string, locale: string) {
  const date = new Date(dateStr);
  return date.toLocaleDateString(locale, { month: "short" });
}

export function getMonthNameFromNum(monthNumber: number): string {
  const dateObj = new Date();
  dateObj.setMonth(monthNumber - 1);
  return dateObj.toLocaleString("default", { month: "short" });
}

export function getDateFormatted(date: any) {
  const today = date ? new Date(date) : new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
}

export function getUAEDateTimeFormatted() {
  return formatDateToYYYYMMDDHHMMSS(getTimeInUAE());
}

// get hours and minutes
export function getFullDateFormatted(date: any) {
  const today = date ? new Date(date) : new Date();
  const hh = String(today.getHours()).padStart(2, "0");
  const min = String(today.getMinutes()).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd + ", " + hh + ":" + min;
}

export function formatDateToYYYYMMDDHHMMSS(date: any) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function convertDateToReadableFormate(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;
  return formattedDate;
}

export function capitalizeWords(string: string) {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

export const mergeListOfStringsByDash = (list: string[] | null) => {
  if (list !== null && list.length)
    return list.reduce((prev, current) => `${prev} - ${current}`);

  return "";
};

export const mergeListOfStrings = (
  list: (string | null)[],
  delimiter: string
) => {
  if (list !== null && list.length)
    return list.reduce((prev, current) => `${prev} ${delimiter} ${current}`);

  return "";
};

export const makeFakePhoneNumbers = (index: number, length: number) => {
  var result = `01${index}`;
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getDomainName = (account: Account) => {
  return account.domain;
};

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "kioskdev" ||
    // [::1] is the IPv6 kioskdev address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ) ||
    window.location.hostname.match(
      /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const isProduction = Boolean(
  window.location.hostname === "www.admin.etihad.anyware.software" ||
    window.location.hostname === "admin.etihad.anyware.software"
);

export const createArray = (size: number) =>
  Array.from({ length: size }, (_, i) => i);

export function convertToCamelCase(text: string) {
  return text.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

export function extractSelectedCheckboxes(keyName: string, data: any) {
  const selected: string[] = [];

  for (let key of Object.getOwnPropertyNames(data)) {
    if (key.includes(keyName)) {
      if (data[key] === true) {
        selected.push(key.replace(keyName, ""));
      }
    }
  }

  return selected;
}
//  original validation
// export function validatePhone(phone: string) {
//   const localAndInternational =
//     /(^[1-9]\d{9}$|^[1-9]\d{9}$|^(\+|00)[0-9]{12,14}$)/;
//   return localAndInternational.test(phone);
// }

// international validation

export function validatePhone(phone: string) {
  const localAndInternational =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  return localAndInternational.test(phone);
}

export async function clearBrowser(reload = true) {
  // remove indexedDB
  const indexedDBs = await window.indexedDB.databases();
  const amplifyDB = indexedDBs.find(
    (db: any) => db.name === "amplify-datastore"
  )?.name;
  if (amplifyDB) window.indexedDB.deleteDatabase(amplifyDB);

  // clear localStorage
  window.localStorage.clear();

  // clear sessionStorage
  window.sessionStorage.clear();

  // reload
  if (reload) window.location.reload();
}

export function hexEncode(str: string) {
  var hex, i;

  var result = "";
  for (i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16);
    result += ("000" + hex).slice(-4);
  }

  return result;
}

export function hexDecode(hexString: string) {
  var j;
  var hexes = hexString.match(/.{1,4}/g) || [];
  var back = "";
  for (j = 0; j < hexes.length; j++) {
    back += String.fromCharCode(parseInt(hexes[j], 16));
  }

  return back;
}

export function isNumeric(num: string) {
  return !isNaN(parseInt(num));
}

export function persistBookingDate(date: Date): Date {
  const bookingsDate: string | null = localStorage.getItem(
    LOCAL_STORAGE.BOOKING_DATE
  );

  if (bookingsDate) return new Date(bookingsDate);

  return date;
}

export function persistSelectedConcept(conceptID: string) {
  const selected: string | null = localStorage.getItem(
    LOCAL_STORAGE.SELECTED_CONCEPT
  );

  return selected ? selected : conceptID;
}

export function excerpt(str: string, maxLength: number): string {
  if (str.length > maxLength) return str.substring(0, maxLength) + "...";

  return str;
}

export function isEquivalentArrays(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    let prob = arr1[i];

    if (arr2.indexOf(prob) === -1) return false;
  }

  return true;
}

export function currentDate(date: Date | string): Date {
  const newDate: Date = new Date();

  if (newDate.getHours() < 4) {
    let yesterday: Date = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday;
  }

  return new Date(date);
}

export function renderPickedDates(
  startDate: Date,
  endDate: Date,
  BookingDateStatus: boolean = false
): string {
  if (BookingDateStatus) {
    if (getDateFormatted(startDate) === getDateFormatted(endDate)) {
      return getDateFormatted(startDate);
    }
    return `${getDateFormatted(startDate)} to ${getDateFormatted(endDate)}`;
  } else {
    if (getDateFormatted(startDate) === getDateFormatted(endDate)) {
      return getDateFormatted(persistBookingDate(startDate));
    }
    return `${getDateFormatted(
      persistBookingDate(startDate)
    )} to ${getDateFormatted(persistBookingDate(endDate))}`;
  }
}

// Old render date filter
// export function renderPickedDates(
//   startDate: Date,
//   endDate: Date,
//   BookingDateStatus: boolean = false
// ): string {
//   const startWeekday = startDate.toLocaleString("default", {
//     weekday: "short",
//   });
//   const endWeekday = endDate.toLocaleString("default", {
//     weekday: "short",
//   });

//   if (BookingDateStatus) {
//     if (getDateFormatted(startDate) === getDateFormatted(endDate))
//       return `${startWeekday} ${getDateFormatted(startDate)}`;

//     return `${startWeekday} ${getDateFormatted(
//       startDate
//     )} to ${endWeekday} ${getDateFormatted(endDate)}`;
//   } else {
//     if (getDateFormatted(startDate) === getDateFormatted(endDate))
//       return `${startWeekday} ${getDateFormatted(
//         persistBookingDate(startDate)
//       )}`;

//     return `${startWeekday} ${getDateFormatted(
//       persistBookingDate(startDate)
//     )} to ${endWeekday} ${getDateFormatted(persistBookingDate(endDate))}`;
//   }
// }

export function getValuePercentage(value: number, total: number): number {
  let percentage = 0;
  percentage = (value / total) * 100;
  return percentage;
}

// Render logo based on concept name
export const renderConceptLogo = (conceptName: string) => {
  if (conceptName.toLowerCase() === AccountDomain.ETIHAD_DOMAIN)
    return etihadLogo;

  return etihadLogo;
};

export const renderAccountLogo = (accountName: string) => {
  const name = accountName.toLowerCase();
  if (name === AccountDomain.ETIHAD_DOMAIN) return etihadLogo;
  else return etihadLogo;
};

export function getTimeInUAE() {
  const currentDate = new Date();
  const options: any = {
    timeZone: "Asia/Dubai",
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const uaeDateTime = new Intl.DateTimeFormat("en-US", options).format(
    currentDate
  );
  const time = new Date(uaeDateTime);
  return time;
}
