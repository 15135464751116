import {
  ModelSortDirection,
  ModelStringKeyConditionInput,
  UpdateOnlineOrderInput,
} from "./../models/GQL_API";
import { OnlineOrderGetOnlineVariables } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import useConcept from "./useConcept";
import { OnlineOrder } from "../models";
import { createOnlineOrder, updateOnlineOrder } from "../graphql/mutations";
import {
  getOnlineOrder,
  listOnlineOrders,
  ordersByConceptID,
} from "../graphql/queries";
import { CreateOnlineOrderInput } from "../models/GQL_API";
import { HeadCell } from "../models/dataTable";
import {
  setListing,
  setNextToken,
  setLastIndex,
  setPagination,
  setFilter,
  nextAction,
  setSelectedFilters,
} from "../store/ducks/onlineOrders";
import { OrderStatus } from "../constants/enums";
import { onCreateOnlineOrder } from "../graphql/subscriptions";
import {
  formatDateToYYYYMMDDHHMMSS,
  getTimeInUAE,
  getUAEDateTimeFormatted,
} from "../helpers/utils";
import { AnyAction } from "redux";

const useOnlineOrder = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const { conceptsGetName } = useConcept("concepts", "concept");

  let nextToken = useSelector((state: any) => state.onlineOrders.nextToken);
  let lastIndex = useSelector((state: any) => state.onlineOrders.lastIndex);
  const storedLimit = useSelector((state: any) => state.onlineOrders.limit);
  const previousTokens = useSelector(
    (state: any) => state.onlineOrders.previousTokens
  );

  let paginationListing = useSelector(
    (state: any) => state.onlineOrders.pagination
  );
  let paginationFilter = useSelector((state: any) => state.onlineOrders.filter);

  const selectedConcept = useSelector((state: any) => state.concepts.selected);

  const session = useSelector((state: any) => state.app.session);

  async function getOnlineByID(params: any) {
    try {
      const { id } = params;
      const onlineOrder: any = await API.graphql({
        query: getOnlineOrder,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return onlineOrder.data.getOnlineOrder;
    } catch (err) {
      throw err;
    }
  }

  async function getOnline(params: OnlineOrderGetOnlineVariables) {
    //do it with index
    try {
      const { userID, conceptID, limit } = params;
      const filter: any = {
        deleted: { eq: "0" },
        userID: { eq: userID },
        conceptID: { eq: conceptID },
      };
      const listing: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: listOnlineOrders,
        variables: { filter, limit: limit ?? 1000 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listOnlineOrders.items;
    } catch (err) {
      showError(err);
    }
  }

  async function fetch(params: any) {
    try {
      const {
        limit,
        startIndex,
        searchText,
        sort,
        moveForward,
        tableID,
        fromDate,
        toDate,
        statusList,
        conceptID,
        conceptsSelectedFilters,
        // orderStatusesSelectedFilters,
        onlineOrdersSelectedFilters,
      } = params;
      let currentNextToken;
      let requestLimit = limit ? limit : storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;
      let listing: any[] = [];

      const filter: any = {
        deleted: { eq: "0" },
        parentOrder: { eq: "none" },
      };
      filter.or = [];
      filter.and = [];

      if (searchText && searchText.length > 0) {
        filter.id = { contains: searchText };
        requestLimit = 10000;
      }

      if (tableID) {
        filter.tableID = { eq: tableID };
      }

      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.and.push({ or: or });
      } else if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }

      if (
        onlineOrdersSelectedFilters &&
        onlineOrdersSelectedFilters.length > 0
      ) {
        for (let status of onlineOrdersSelectedFilters) {
          filter.or.push({ status: { eq: status.value } });
        }
      }

      if (statusList && statusList.length > 0) {
        let or = [];
        for (let status of statusList) {
          or.push({ status: { eq: status } });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }

      if (fromDate) {
        const fromDateEdited = new Date(fromDate);
        fromDateEdited.setHours(0, 0, 0, 0);

        filter.and.push({
          createdAt: { ge: formatDateToYYYYMMDDHHMMSS(fromDateEdited) },
        });
      }

      if (toDate) {
        // Add 1 day to toDay to get all date range's orders
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        toDatePlusOneDay.setHours(0, 0, 0, 0);

        filter.and.push({
          createdAt: {
            lt: formatDateToYYYYMMDDHHMMSS(toDatePlusOneDay),
          },
        });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      if (
        paginationFilter &&
        paginationFilter.toString() !==
          [
            searchText,
            conceptID,
            conceptsSelectedFilters,
            onlineOrdersSelectedFilters,
            fromDate,
            toDate,
          ].toString()
      ) {
        dispatch(setPagination([]));
        dispatch(setNextToken(null));
        dispatch(setLastIndex(0));

        paginationListing = [];
        requestToken = null;
      }

      let allOrders: any = [];
      let firstList: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: listOnlineOrders,
        variables: {
          filter,
          limit: requestLimit ? requestLimit : 50,
          nextToken: requestToken,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      allOrders = allOrders.concat(firstList.data.listOnlineOrders.items);
      let token = firstList.data.listOnlineOrders.nextToken;
      let lastList: any = null;
      while (
        token &&
        token.length > 0 &&
        allOrders.length <= (requestLimit ? requestLimit : 50)
      ) {
        let nextOrdersList: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
          query: listOnlineOrders,
          variables: {
            filter,
            limit: requestLimit ? requestLimit : 50,
            nextToken: token,
          },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        allOrders = allOrders.concat(
          nextOrdersList.data.listOnlineOrders.items
        );
        token = nextOrdersList.data.listOnlineOrders.nextToken;
        lastList = nextOrdersList;
        if (allOrders.length >= (requestLimit ? requestLimit : 50)) {
          token = null;
        }
      }
      if (moveForward) {
        currentNextToken = lastList?.data?.listOnlineOrders?.nextToken;
        dispatch(nextAction(currentNextToken, requestPreviousTokens));
        dispatch(setNextToken(currentNextToken));
      }

      listing = allOrders;
      if (sort && listing.length > 0) {
        let ordersList = listing.sort((a: any, b: any) => {
          return b.createdAt > a.createdAt ? 1 : -1;
        });
        return ordersList;
      }

      dispatch(setPagination(paginationListing.concat(listing)));
      dispatch(
        setFilter([
          searchText,
          conceptID,
          conceptsSelectedFilters,
          onlineOrdersSelectedFilters,
          fromDate,
          toDate,
        ])
      );

      return listing;
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function fetchByConceptID(params: any) {
    try {
      const {
        limit,
        startIndex,
        searchText,
        sort,
        moveForward,
        tableID,
        fromDate,
        toDate,
        statusList,
        conceptID,
        conceptsSelectedFilters,
        onlineOrdersSelectedFilters,
      } = params;

      let currentNextToken;
      let requestLimit = limit ? limit : storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;
      let listing: any[] = [];
      const createdAtFilter: ModelStringKeyConditionInput = {};

      const filter: any = {
        deleted: { eq: "0" },
      };
      filter.or = [];
      filter.and = [];

      // Search order by ID
      if (searchText && searchText.length > 0) {
        filter.id = { contains: searchText };
      }

      if (tableID) {
        filter.tableID = { eq: tableID };
      }

      if (
        onlineOrdersSelectedFilters &&
        onlineOrdersSelectedFilters.length > 0
      ) {
        for (let status of onlineOrdersSelectedFilters) {
          filter.or.push({ status: { eq: status.value } });
        }
      }
      // Need test
      if (statusList && statusList.length > 0) {
        let or = [];
        for (let status of statusList) {
          or.push({ status: { eq: status } });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      // Pagination
      if (
        paginationFilter &&
        paginationFilter.toString() !==
          [
            searchText,
            conceptID,
            conceptsSelectedFilters,
            onlineOrdersSelectedFilters,
            fromDate,
            toDate,
          ].toString()
      ) {
        dispatch(setPagination([]));
        dispatch(setNextToken(null));
        dispatch(setLastIndex(0));

        paginationListing = [];
        requestToken = null;
      }

      // Get selected concepts (concept filter overwrite slected concept)
      let selectedConcept =
        conceptsSelectedFilters && conceptsSelectedFilters.length > 0
          ? conceptsSelectedFilters[0].id
          : conceptID;

      const variables: any = {
        filter,
        conceptID: selectedConcept,
        SortDirection: ModelSortDirection.DESC,
        limit: requestLimit,
        nextToken: requestToken,
      };

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      const itemsList: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: ordersByConceptID,
        variables,
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentPageItems = itemsList?.data?.ordersByConceptID.items;
      listing = listing.concat(currentPageItems);
      currentNextToken = itemsList?.data?.ordersByConceptID.nextToken;

      if (moveForward) {
        currentNextToken = itemsList?.data?.ordersByConceptID?.nextToken;
        dispatch(nextAction(currentNextToken, requestPreviousTokens));
        dispatch(setNextToken(currentNextToken));
      }

      return listing;
    } catch (err) {
      showError(err);
      return [];
    }
  }

  async function fetchAllOnline(params: any) {
    try {
      const {
        conceptID,
        orderStatusesSelectedFilters,
        adminsSelectedFilters,
        fromDate,
        toDate,
        limit,
        startIndex,
        statusList,
        acceptedByWaiterID,
        acceptedByWaiterName,
        deliveredByWaiterID,
        sort,
        conceptsList,
        preparationAreaID,
        parentOrder,
        tableID,
      } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };
      filter.and = [];
      filter.or = [];

      if (conceptsList) {
        let or = [];
        for (let concept of conceptsList) {
          or.push({ conceptID: { eq: concept } });
        }
        if (or.length === 0) {
          or.push({ conceptID: { eq: selectedConcept } });
          filter.and.push({ or: or });
        } else {
          filter.and.push({ or: or });
        }
      }
      if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }
      if (
        orderStatusesSelectedFilters &&
        orderStatusesSelectedFilters.length > 0
      ) {
        filter.or = [];
        for (let filter of orderStatusesSelectedFilters) {
          filter.or.push({ statusID: { eq: filter.id } });
        }
      }

      if (adminsSelectedFilters && adminsSelectedFilters.length > 0) {
        const adminFilters = [];
        for (let filter of adminsSelectedFilters) {
          adminFilters.push({
            dispatcherID: { eq: filter.Attributes[0].Value },
          });
        }
        filter.or = adminFilters;
      }

      if (fromDate) {
        const fromDateEdited = new Date(fromDate);
        fromDateEdited.setHours(0, 0, 0, 0);

        filter.and.push({
          createdAt: { ge: formatDateToYYYYMMDDHHMMSS(fromDateEdited) },
        });
      }

      if (toDate) {
        // Add 1 day to toDay to get all date range's orders
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        toDatePlusOneDay.setHours(0, 0, 0, 0);

        filter.and.push({
          createdAt: {
            lt: formatDateToYYYYMMDDHHMMSS(toDatePlusOneDay),
          },
        });
      }

      if (statusList && statusList.length > 0) {
        let or = [];
        for (let status of statusList) {
          or.push({ status: { eq: status } });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }
      if (deliveredByWaiterID || acceptedByWaiterID) {
        let or = [];
        if (deliveredByWaiterID) {
          or.push({ deliveredByWaiterID: { eq: deliveredByWaiterID } });
        }
        if (acceptedByWaiterID) {
          let and = [
            { acceptedByWaiterID: { eq: acceptedByWaiterID } },
            { acceptedByWaiterName: { eq: acceptedByWaiterName } },
          ];
          or.push({ and: and });
        }
        if (or.length > 0) filter.and.push({ or: or });
      }
      if (preparationAreaID) {
        // List child orders only (KDS)
        filter.preparationAreaID = { eq: preparationAreaID };
        filter.parentOrder = { ne: "none" };
      }
      if (parentOrder) {
        filter.parentOrder = { eq: parentOrder };
      }
      if (tableID) {
        filter.tableID = { eq: tableID };
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      let listing: any[] = [];
      let nextToken: string | null = null;

      do {
        const orderData: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
          query: listOnlineOrders,
          variables: {
            filter,
            limit: limit ?? 10000,
            nextToken: nextToken,
          },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        const currentPageItems = orderData?.data?.listOnlineOrders.items;
        listing = listing.concat(currentPageItems);
        nextToken = orderData?.data?.listOnlineOrders.nextToken;
      } while (nextToken);

      if (sort) {
        let ordersList = listing.sort((a: any, b: any) => {
          return b.createdAt > a.createdAt ? 1 : -1;
        });
        return ordersList;
      }

      dispatch(setListing(listing));

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchAllByConceptID(params: any) {
    try {
      const { fromDate, toDate, conceptID } = params;
      let listing: any[] = [];
      const createdAtFilter: ModelStringKeyConditionInput = {};

      const filter: any = {
        deleted: { eq: "0" },
      };
      filter.or = [];
      filter.and = [];

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const variables: any = {
        filter,
        conceptID,
        SortDirection: ModelSortDirection.DESC,
        limit: 100000,
      };

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      let nextToken: string | null = null;

      do {
        if (nextToken) {
          variables.nextToken = nextToken;
        }

        const itemsList: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
          query: ordersByConceptID,
          variables,
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        const currentPageItems = itemsList?.data?.ordersByConceptID.items;
        listing = listing.concat(currentPageItems);
        nextToken = itemsList?.data?.ordersByConceptID.nextToken;
      } while (nextToken);

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function updateOnline(params: any, session: any) {
    try {
      const {
        orderedItems,
        deliveryFee,
        status,
        orderAddress,
        shift,
        acceptedByWaiterID,
        deliveredByWaiterID,
        acceptedByWaiterName,
        acceptedByWaiterAt,
        sentToKitchenAt,
        acceptedByKitchenAt,
        readyAt,
        outForDeliveryAt,
        deliveredAt,
        canceledAt,
        children,
        childrenReadyAt,
      } = params;

      if (orderedItems) {
        for (let i = 0; i < orderedItems.length; i++) {
          delete orderedItems[i].__typename;
        }
      }

      let original = await getOnlineByID(params);
      if (original.status === OrderStatus.cancelled) {
        showError("This Order has been cancelled");
        return;
      }
      if (
        status &&
        (status === OrderStatus.acceptedByWaiter ||
          status === OrderStatus.outForDelivery) &&
        original.status === status
      ) {
        showError("order is accepted by another waiter");
        return;
      }
      if (
        status &&
        status === OrderStatus.acceptedByWaiter &&
        original.status === OrderStatus.cancelled
      ) {
        showError("order is cancelled by the customer");
        return;
      }

      let newChildrenStatus = [...original.childrenStatus];
      let originalReadyAt = original.readyAt;
      let originalOutForDeliveryAt = original.outForDeliveryAt;
      let originalDeliveredAt = original.deliveredAt;
      let originalStatus = original.status;

      /* Pending items checker */
      let pendingItemFlag = false;
      for (let item of original.orderedItems) {
        if (item.pending) {
          pendingItemFlag = true;
          break;
        }
      }

      if (children) {
        if (children.deliveredAt) {
          children.deliveredAt = formatDateToYYYYMMDDHHMMSS(
            children.deliveredAt
          );
        }

        if (typeof children === "number" && newChildrenStatus.length > 0) {
          // In a split order, update the number of children.
          newChildrenStatus[0] = +newChildrenStatus[0] + children;
        } else {
          newChildrenStatus.push(JSON.stringify(children));
        }

        let childrenDelivered = newChildrenStatus.filter((child: any) => {
          return child !== "ready";
        });

        /*
          Check if all order items delivered completely
          By Checking if all children delivered and there is no pending items
        */
        if (
          childrenDelivered.length === +newChildrenStatus[0] + 1 &&
          !pendingItemFlag
        ) {
          originalReadyAt = children.readyAt;
          originalOutForDeliveryAt = children.outForDeliveryAt;
          originalDeliveredAt = children.deliveredAt;
          originalStatus = OrderStatus.delivered;
        }

        // Update item to be marked as delivered
        for (let item of original.orderedItems) {
          if (!item.pending) {
            item.delivered = true;
          }
          delete item.__typename;
        }
      }
      if (childrenReadyAt) {
        newChildrenStatus.push("ready");
        let childrenReady = newChildrenStatus.filter((child: any) => {
          return child === "ready";
        });

        if (
          childrenReady.length === +newChildrenStatus[0] &&
          !pendingItemFlag
        ) {
          originalStatus = OrderStatus.childrenReady;
          originalReadyAt = formatDateToYYYYMMDDHHMMSS(childrenReadyAt);
        }
      }

      let updateInput: UpdateOnlineOrderInput = {
        id: original.id,
        status: status ? status : originalStatus,
        orderAddress: orderAddress ? orderAddress : original.orderAddress,
        shift: shift ? shift : original.shift,
        acceptedByWaiterID: acceptedByWaiterID
          ? acceptedByWaiterID
          : original.acceptedByWaiterID,
        deliveredByWaiterID: deliveredByWaiterID
          ? deliveredByWaiterID
          : original.deliveredByWaiterID,
        acceptedByWaiterName: acceptedByWaiterName
          ? acceptedByWaiterName
          : original.acceptedByWaiterName,
        acceptedByWaiterAt: acceptedByWaiterAt
          ? formatDateToYYYYMMDDHHMMSS(acceptedByWaiterAt)
          : original.acceptedByWaiterAt,
        sentToKitchenAt: sentToKitchenAt
          ? formatDateToYYYYMMDDHHMMSS(sentToKitchenAt)
          : original.sentToKitchenAt,
        acceptedByKitchenAt: acceptedByKitchenAt
          ? formatDateToYYYYMMDDHHMMSS(acceptedByKitchenAt)
          : original.acceptedByKitchenAt,
        readyAt: readyAt
          ? formatDateToYYYYMMDDHHMMSS(readyAt)
          : originalReadyAt,
        outForDeliveryAt: outForDeliveryAt
          ? formatDateToYYYYMMDDHHMMSS(outForDeliveryAt)
          : originalOutForDeliveryAt,
        deliveredAt: deliveredAt
          ? formatDateToYYYYMMDDHHMMSS(deliveredAt)
          : originalDeliveredAt,
        canceledAt: canceledAt
          ? formatDateToYYYYMMDDHHMMSS(canceledAt)
          : original.canceledAt,
        childrenStatus: newChildrenStatus,

        specialRequest: params.specialRequest
          ? params.specialRequest
          : original.specialRequest,

        _version: original._version,
      };

      if (children) {
        updateInput.orderedItems = original.orderedItems;
      }

      if (orderedItems !== null && orderedItems !== undefined) {
        let totalPrice = 0;
        updateInput.orderedItems = orderedItems;

        for (let item of orderedItems) {
          totalPrice = totalPrice + (item?.price ?? 0) * (item?.quantity ?? 0);
        }
        /* Calculate VAT */
        updateInput.totalAmount = totalPrice * 1.14;

        /* Add Delivery Fee */
        if (deliveryFee) {
          updateInput.totalAmount += deliveryFee;
        }

        /* Round up final value */
        updateInput.totalAmount = Number(updateInput.totalAmount.toFixed(2));
      }

      const OnlineOrder: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: updateOnlineOrder,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      if (
        OnlineOrder.data.updateOnlineOrder.status !==
          OrderStatus.acceptedByWaiter ||
        OnlineOrder.data.updateOnlineOrder.status !== OrderStatus.outForDelivery
      ) {
        showConfirm(`Order has been processed successfully`);
      }

      return OnlineOrder.data.updateOnlineOrder;
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function createOnline(params: any, isAuth = true) {
    let {
      userID,
      userName,
      OrderData,
      acceptedByWaiterID,
      deliveredByWaiterID,
      tableID,
      shift,
      tableName,
      childrenOrderData,
      acceptedByWaiterName,
    } = params;
    try {
      let input: CreateOnlineOrderInput;
      if (!childrenOrderData) {
        if (OrderData.orderedItems)
          for (let i = 0; i < OrderData.orderedItems.length; i++) {
            // Init with pending status
            OrderData.orderedItems[i].pending = true;
            delete OrderData.orderedItems[i].__typename;
          }
        if (OrderData.pendingItems)
          for (let i = 0; i < OrderData.pendingItems.length; i++) {
            delete OrderData.pendingItems[i].__typename;
          }

        const createInput: CreateOnlineOrderInput = {
          cartID: OrderData.cartID,
          conceptID: OrderData.conceptID,
          userID: OrderData.userID,
          userName: OrderData.name,
          orderedItems: OrderData.orderedItems,
          pendingItems: OrderData.pendingItems,
          specialRequest: OrderData.specialRequest,
          status: acceptedByWaiterID
            ? OrderStatus.acceptedByWaiter
            : OrderStatus.pending,
          acceptedByWaiterID: acceptedByWaiterID ? acceptedByWaiterID : null,
          acceptedByWaiterName: acceptedByWaiterName
            ? acceptedByWaiterName
            : null,
          deliveredByWaiterID: deliveredByWaiterID ? deliveredByWaiterID : null,
          tableID: tableID ? tableID : null,
          tableName: tableName ? tableName : null,
          shift: shift ? shift : null,
          orderType: OrderData.orderType,
          orderTime: OrderData.orderTime,
          deliveryFee: OrderData.deliveryFee,
          orderAddress: OrderData.orderAddress,
          totalAmount: (
            OrderData.totalPrice * 1.14 +
            OrderData.deliveryFee
          ).toFixed(2),
          acceptedByWaiterAt: "",
          sentToKitchenAt: "",
          acceptedByKitchenAt: "",
          readyAt: "",
          outForDeliveryAt: "",
          deliveredAt: "",
          canceledAt: "",
          createdAt: getUAEDateTimeFormatted(),
          childrenStatus: [],
          parentOrder: "none",
          preparationAreaID: "",
          createdByID: userID,
          createdByName: userName,
        };
        input = createInput;
      } else {
        if (childrenOrderData.orderedItems)
          for (let i = 0; i < childrenOrderData.orderedItems.length; i++) {
            delete childrenOrderData.orderedItems[i].__typename;
          }
        if (childrenOrderData.pendingItems)
          for (let i = 0; i < childrenOrderData.pendingItems.length; i++) {
            delete childrenOrderData.pendingItems[i].__typename;
          }

        const createInput: CreateOnlineOrderInput = {
          parentOrder: childrenOrderData.parentOrder,
          preparationAreaID: childrenOrderData.preparationAreaID,
          orderedItems: childrenOrderData.orderedItems,
          pendingItems: childrenOrderData.pendingItems,
          cartID: childrenOrderData.cartID,
          conceptID: childrenOrderData.conceptID,
          userID: childrenOrderData.userID,
          userName: childrenOrderData.userName,
          specialRequest: childrenOrderData.specialRequest,
          status: childrenOrderData.status,
          acceptedByWaiterID: childrenOrderData.acceptedByWaiterID,
          acceptedByWaiterName: childrenOrderData.acceptedByWaiterName,
          deliveredByWaiterID: childrenOrderData.deliveredByWaiterID,
          tableID: childrenOrderData.tableID,
          tableName: childrenOrderData.tableName,
          shift: childrenOrderData.shift,
          orderType: childrenOrderData.orderType,
          orderTime: childrenOrderData.orderTime,
          deliveryFee: childrenOrderData.deliveryFee,
          orderAddress: childrenOrderData.orderAddress,
          totalAmount: childrenOrderData.totalAmount,
          acceptedByWaiterAt: childrenOrderData.acceptedByWaiterAt,
          sentToKitchenAt: formatDateToYYYYMMDDHHMMSS(
            childrenOrderData.sentToKitchenAt
          ),
          acceptedByKitchenAt: childrenOrderData.acceptedByKitchenAt,
          readyAt: childrenOrderData.readyAt,
          outForDeliveryAt: childrenOrderData.outForDeliveryAt,
          deliveredAt: childrenOrderData.deliveredAt,
          canceledAt: childrenOrderData.canceledAt,
          createdAt: childrenOrderData.createdAt,
          createdByID: childrenOrderData.createdByID,
          createdByName: childrenOrderData.createdByName,
          childrenStatus: [],
        };
        input = createInput;
      }

      // Create Order Query
      const OnlineOrder: any = await API.graphql<GraphQLQuery<OnlineOrder>>({
        query: createOnlineOrder,
        variables: { input: input },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      if (!childrenOrderData) {
        showConfirm(`New ${singleName} has been created successfully`);
      }
      return OnlineOrder.data.createOnlineOrder;
    } catch (err) {
      console.log(err);
    }
  }

  async function exportAll(params: any) {
    try {
      const data = await fetch(params);

      let exportedData = [];

      for (let OnlineOrder of data!) {
        let row: any = { ...OnlineOrder };
        if (OnlineOrder.id) {
          row.id = OnlineOrder.id.split("-")[0];
        }

        if (OnlineOrder.conceptID) {
          row.conceptID = conceptsGetName({
            id: OnlineOrder.conceptID,
            listing: params.conceptsListing,
          });
        }

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "conceptID",
      numeric: false,
      disablePadding: false,
      label: "Concept",
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Order Number",
    },
    {
      id: "orderStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "reason",
      numeric: false,
      disablePadding: false,
      label: "Reason",
    },

    {
      id: "orderType",
      numeric: false,
      disablePadding: false,
      label: "Order Type",
    },
    {
      id: "orderTime",
      numeric: false,
      disablePadding: false,
      label: "Order time",
    },
    {
      id: "tableName",
      numeric: false,
      disablePadding: false,
      label: "Table Name",
    },
    {
      id: "userName",
      numeric: false,
      disablePadding: false,
      label: "User name",
    },
    {
      id: "acceptedByWaiterName",
      numeric: false,
      disablePadding: false,
      label: "Waiter Name",
    },
    {
      id: "specialRequest",
      numeric: false,
      disablePadding: false,
      label: "Special request",
    },
    {
      id: "acceptedByWaiterAt",
      numeric: false,
      disablePadding: false,
      label: "Accepted At",
    },
    {
      id: "outForDeliveryAt",
      numeric: false,
      disablePadding: false,
      label: "Delivered At",
    },
    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created by",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created at",
    },
    {
      id: "Actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const dataCells: readonly string[] = [
    "conceptID",
    "id",
    "status",
    "reason",
    "orderType",
    "orderTime",
    "tableName",
    "userName",
    "acceptedByWaiterName",
    "specialRequest",
    "acceptedByWaiterAt",
    "outForDeliveryAt",
  ];

  const api: any = {};

  api[`${listingName}Model`] = OnlineOrder as any;
  api[`${listingName}CreateSubscription`] = onCreateOnlineOrder;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}CreateOnline`] = createOnline;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}GetOnlineByID`] = getOnlineByID;
  api[`${listingName}UpdateOnline`] = updateOnline;

  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByConceptID`] = fetchByConceptID;

  api[`${listingName}FetchAllOnline`] = fetchAllOnline;
  api[`${listingName}FetchAllByConceptID`] = fetchAllByConceptID;
  api[`${listingName}ChangeListing`] = (listing: OnlineOrder[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilter(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };
  return api;
};

export default useOnlineOrder;
