/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      scannedCount
      count
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      scannedCount
      count
      __typename
    }
  }
`;
export const ByPhoneNumber = /* GraphQL */ `
  query ByPhoneNumber(
    $phone_number: String!
    $deletedNameCreatedAt: ModelUserUsersByPhoneNumberCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByPhoneNumber(
      phone_number: $phone_number
      deletedNameCreatedAt: $deletedNameCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      scannedCount
      count
      __typename
    }
  }
`;
export const getEmailVerification = /* GraphQL */ `
  query GetEmailVerification($id: ID!) {
    getEmailVerification(id: $id) {
      id
      accountID
      name
      email
      verification_code
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEmailVerifications = /* GraphQL */ `
  query ListEmailVerifications(
    $filter: ModelEmailVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        email
        verification_code
        createdAt
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEmailVerifications = /* GraphQL */ `
  query SyncEmailVerifications(
    $filter: ModelEmailVerificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmailVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        email
        verification_code
        createdAt
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      accountID
      bookingID
      guestName
      guestId
      value
      reason
      createdAt
      deleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        bookingID
        guestName
        guestId
        value
        reason
        createdAt
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReviews = /* GraphQL */ `
  query SyncReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        bookingID
        guestName
        guestId
        value
        reason
        createdAt
        deleted
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      applicationLink
      applicationVersion
      enableApplication
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logo
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        applicationLink
        applicationVersion
        enableApplication
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        kpiReportResetFrequency
        agentBarResetFrequency
        dynamicStatus
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        logo
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        applicationLink
        applicationVersion
        enableApplication
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        kpiReportResetFrequency
        agentBarResetFrequency
        dynamicStatus
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSocialLink = /* GraphQL */ `
  query GetSocialLink($id: ID!) {
    getSocialLink(id: $id) {
      id
      name
      icon
      slug
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSocialLinks = /* GraphQL */ `
  query ListSocialLinks(
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocialLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSocialLinks = /* GraphQL */ `
  query SyncSocialLinks(
    $filter: ModelSocialLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSocialLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        icon
        slug
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($id: ID!) {
    getFeature(id: $id) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFeatures = /* GraphQL */ `
  query SyncFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLanguages = /* GraphQL */ `
  query SyncLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        code
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        attachments
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMedia = /* GraphQL */ `
  query SyncMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedia(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        attachments
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAttachments = /* GraphQL */ `
  query SyncAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getConcept = /* GraphQL */ `
  query GetConcept($id: ID!) {
    getConcept(id: $id) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      autoSendOrder
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      receiveWaiters
      WaiterNotificationOption
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listConcepts = /* GraphQL */ `
  query ListConcepts(
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        autoSendOrder
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        receiveWaiters
        WaiterNotificationOption
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncConcepts = /* GraphQL */ `
  query SyncConcepts(
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        autoSendOrder
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        receiveWaiters
        WaiterNotificationOption
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getParentConcept = /* GraphQL */ `
  query GetParentConcept($id: ID!) {
    getParentConcept(id: $id) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues
      index
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listParentConcepts = /* GraphQL */ `
  query ListParentConcepts(
    $filter: ModelParentConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParentConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        createdAt
        createdByID
        createdByName
        image
        name
        logo
        type
        venues
        index
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncParentConcepts = /* GraphQL */ `
  query SyncParentConcepts(
    $filter: ModelParentConceptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncParentConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deleted
        createdAt
        createdByID
        createdByName
        image
        name
        logo
        type
        venues
        index
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      accountID
      name
      description
      color
      requireApproval
      status
      agents
      groupSettings
      numToUpgrade
      numToDowngrade
      upgradeGroup
      downgradeGroup
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        description
        color
        requireApproval
        status
        agents
        groupSettings
        numToUpgrade
        numToDowngrade
        upgradeGroup
        downgradeGroup
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        description
        color
        requireApproval
        status
        agents
        groupSettings
        numToUpgrade
        numToDowngrade
        upgradeGroup
        downgradeGroup
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGroupSetting = /* GraphQL */ `
  query GetGroupSetting($id: ID!) {
    getGroupSetting(id: $id) {
      id
      groupID
      statusID
      needsVerification
      needsDeposit
      isDefault
      amount
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGroupSettings = /* GraphQL */ `
  query ListGroupSettings(
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        statusID
        needsVerification
        needsDeposit
        isDefault
        amount
        days
        timeSlots
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGroupSettings = /* GraphQL */ `
  query SyncGroupSettings(
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroupSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupID
        statusID
        needsVerification
        needsDeposit
        isDefault
        amount
        days
        timeSlots
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomerStats = /* GraphQL */ `
  query GetCustomerStats($id: ID!) {
    getCustomerStats(id: $id) {
      id
      conceptID
      totalReservation
      confirmed
      notConfirmed
      canceled
      checkIn
      noShow
      userID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCustomerStats = /* GraphQL */ `
  query ListCustomerStats(
    $filter: ModelCustomerStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        totalReservation
        confirmed
        notConfirmed
        canceled
        checkIn
        noShow
        userID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCustomerStats = /* GraphQL */ `
  query SyncCustomerStats(
    $filter: ModelCustomerStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomerStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        totalReservation
        confirmed
        notConfirmed
        canceled
        checkIn
        noShow
        userID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFlag = /* GraphQL */ `
  query GetFlag($id: ID!) {
    getFlag(id: $id) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFlags = /* GraphQL */ `
  query ListFlags(
    $filter: ModelFlagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        icon
        color
        customers
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFlags = /* GraphQL */ `
  query SyncFlags(
    $filter: ModelFlagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFlags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        icon
        color
        customers
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInterest = /* GraphQL */ `
  query GetInterest($id: ID!) {
    getInterest(id: $id) {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInterests = /* GraphQL */ `
  query ListInterests(
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        icon
        color
        customers
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInterests = /* GraphQL */ `
  query SyncInterests(
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInterests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        icon
        color
        customers
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReservationStatus = /* GraphQL */ `
  query GetReservationStatus($id: ID!) {
    getReservationStatus(id: $id) {
      id
      accountID
      name
      category
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reservationStatusImageId
      __typename
    }
  }
`;
export const listReservationStatuses = /* GraphQL */ `
  query ListReservationStatuses(
    $filter: ModelReservationStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservationStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        category
        image {
          id
          mediaID
          fileUrl
          filename
          filetype
          fileSize
          alternativeText
          caption
          description
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        reservationStatusImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReservationStatuses = /* GraphQL */ `
  query SyncReservationStatuses(
    $filter: ModelReservationStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReservationStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        category
        image {
          id
          mediaID
          fileUrl
          filename
          filetype
          fileSize
          alternativeText
          caption
          description
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        reservationStatusImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTimeSlot = /* GraphQL */ `
  query GetTimeSlot($id: ID!) {
    getTimeSlot(id: $id) {
      id
      conceptID
      name
      friendlyName
      bookings
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTimeSlots = /* GraphQL */ `
  query ListTimeSlots(
    $filter: ModelTimeSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        friendlyName
        bookings
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTimeSlots = /* GraphQL */ `
  query SyncTimeSlots(
    $filter: ModelTimeSlotFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTimeSlots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        friendlyName
        bookings
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getArea = /* GraphQL */ `
  query GetArea($id: ID!) {
    getArea(id: $id) {
      id
      conceptID
      name
      isDefault
      expiryDate
      days
      timeSlots
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAreas = /* GraphQL */ `
  query ListAreas(
    $filter: ModelAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        isDefault
        expiryDate
        days
        timeSlots
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAreas = /* GraphQL */ `
  query SyncAreas(
    $filter: ModelAreaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAreas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        isDefault
        expiryDate
        days
        timeSlots
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPlanItem = /* GraphQL */ `
  query GetPlanItem($id: ID!) {
    getPlanItem(id: $id) {
      id
      areaID
      conceptID
      xPosition
      yPosition
      name
      isReserved
      bookings
      table
      object
      lockDays
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPlanItems = /* GraphQL */ `
  query ListPlanItems(
    $filter: ModelPlanItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        areaID
        conceptID
        xPosition
        yPosition
        name
        isReserved
        bookings
        table
        object
        lockDays
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPlanItems = /* GraphQL */ `
  query SyncPlanItems(
    $filter: ModelPlanItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlanItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        areaID
        conceptID
        xPosition
        yPosition
        name
        isReserved
        bookings
        table
        object
        lockDays
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const planItemByConceptID = /* GraphQL */ `
  query PlanItemByConceptID(
    $conceptID: String!
    $areaIDName: ModelPlanItemByConceptIDCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlanItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    planItemByConceptID(
      conceptID: $conceptID
      areaIDName: $areaIDName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        areaID
        conceptID
        xPosition
        yPosition
        name
        isReserved
        bookings
        table
        object
        lockDays
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTable = /* GraphQL */ `
  query GetTable($id: ID!) {
    getTable(id: $id) {
      id
      conceptID
      label
      name
      width
      height
      capacity
      image
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTables = /* GraphQL */ `
  query ListTables(
    $filter: ModelTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        label
        name
        width
        height
        capacity
        image
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTables = /* GraphQL */ `
  query SyncTables(
    $filter: ModelTableFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        label
        name
        width
        height
        capacity
        image
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getObject = /* GraphQL */ `
  query GetObject($id: ID!) {
    getObject(id: $id) {
      id
      conceptID
      label
      name
      image {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      width
      height
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      objectImageId
      __typename
    }
  }
`;
export const listObjects = /* GraphQL */ `
  query ListObjects(
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        label
        name
        image {
          id
          mediaID
          fileUrl
          filename
          filetype
          fileSize
          alternativeText
          caption
          description
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        width
        height
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        objectImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncObjects = /* GraphQL */ `
  query SyncObjects(
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncObjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        label
        name
        image {
          id
          mediaID
          fileUrl
          filename
          filetype
          fileSize
          alternativeText
          caption
          description
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        width
        height
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        objectImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFriend = /* GraphQL */ `
  query GetFriend($id: ID!) {
    getFriend(id: $id) {
      id
      customerId
      friendId
      bookingID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFriends = /* GraphQL */ `
  query ListFriends(
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerId
        friendId
        bookingID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFriends = /* GraphQL */ `
  query SyncFriends(
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFriends(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customerId
        friendId
        bookingID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      depositValue
      depositStatus
      eventStatus
      eventName
      paymentStatus
      paymentValue
      disablePartialPayment
      disablePayment
      eventDate
      eventGuests
      timeSlotID
      timeSlots
      conceptID
      mainGuest
      customerName
      customerPhone
      customerGroup
      accompaniedCount
      channel
      statusID
      rating
      review
      confirmationHash
      isConfirmedByUser
      date
      tables
      tableName
      comments
      lastComment
      commentCreatedBy
      commentCreatedDate
      timeLeft
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        depositValue
        depositStatus
        eventStatus
        eventName
        paymentStatus
        paymentValue
        disablePartialPayment
        disablePayment
        eventDate
        eventGuests
        timeSlotID
        timeSlots
        conceptID
        mainGuest
        customerName
        customerPhone
        customerGroup
        accompaniedCount
        channel
        statusID
        rating
        review
        confirmationHash
        isConfirmedByUser
        date
        tables
        tableName
        comments
        lastComment
        commentCreatedBy
        commentCreatedDate
        timeLeft
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBookings = /* GraphQL */ `
  query SyncBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        depositValue
        depositStatus
        eventStatus
        eventName
        paymentStatus
        paymentValue
        disablePartialPayment
        disablePayment
        eventDate
        eventGuests
        timeSlotID
        timeSlots
        conceptID
        mainGuest
        customerName
        customerPhone
        customerGroup
        accompaniedCount
        channel
        statusID
        rating
        review
        confirmationHash
        isConfirmedByUser
        date
        tables
        tableName
        comments
        lastComment
        commentCreatedBy
        commentCreatedDate
        timeLeft
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      message
      customerId
      bookingId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        customerId
        bookingId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        message
        customerId
        bookingId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTimeline = /* GraphQL */ `
  query GetTimeline($id: ID!) {
    getTimeline(id: $id) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTimelines = /* GraphQL */ `
  query ListTimelines(
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTimelines = /* GraphQL */ `
  query SyncTimelines(
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTimelines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const timelineByCustomerID = /* GraphQL */ `
  query TimelineByCustomerID(
    $customerId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timelineByCustomerID(
      customerId: $customerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getServingArea = /* GraphQL */ `
  query GetServingArea($id: ID!) {
    getServingArea(id: $id) {
      id
      name
      fees
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listServingAreas = /* GraphQL */ `
  query ListServingAreas(
    $filter: ModelServingAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServingAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        fees
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncServingAreas = /* GraphQL */ `
  query SyncServingAreas(
    $filter: ModelServingAreaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServingAreas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        fees
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCall = /* GraphQL */ `
  query GetCall($id: ID!) {
    getCall(id: $id) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const listCalls = /* GraphQL */ `
  query ListCalls(
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phone_number
        customer {
          id
          accountID
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          facebook
          instagram
          group
          phones
          flags
          flagsName
          interests
          interestsName
          status
          stats
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        createdByID
        createdByName
        endedAt
        duration
        date
        employeeId
        updatedAt
        _version
        _deleted
        _lastChangedAt
        callCustomerId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCalls = /* GraphQL */ `
  query SyncCalls(
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCalls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        phone_number
        customer {
          id
          accountID
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          website
          zoneinfo
          verification_code
          facebook
          instagram
          group
          phones
          flags
          flagsName
          interests
          interestsName
          status
          stats
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        createdByID
        createdByName
        endedAt
        duration
        date
        employeeId
        updatedAt
        _version
        _deleted
        _lastChangedAt
        callCustomerId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      accountID
      conceptID
      name
      description
      channel
      message
      from
      enabled
      autoSend
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        conceptID
        name
        description
        channel
        message
        from
        enabled
        autoSend
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        conceptID
        name
        description
        channel
        message
        from
        enabled
        autoSend
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminRole = /* GraphQL */ `
  query GetAdminRole($id: ID!) {
    getAdminRole(id: $id) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminRoles = /* GraphQL */ `
  query ListAdminRoles(
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminRoles = /* GraphQL */ `
  query SyncAdminRoles(
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminGroup = /* GraphQL */ `
  query GetAdminGroup($id: ID!) {
    getAdminGroup(id: $id) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminGroups = /* GraphQL */ `
  query ListAdminGroups(
    $filter: ModelAdminGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        roles
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminGroups = /* GraphQL */ `
  query SyncAdminGroups(
    $filter: ModelAdminGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        roles
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserConcepts = /* GraphQL */ `
  query GetUserConcepts($id: ID!) {
    getUserConcepts(id: $id) {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserConcepts = /* GraphQL */ `
  query ListUserConcepts(
    $filter: ModelUserConceptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        defaultConcept
        concepts
        conceptsRoles
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserConcepts = /* GraphQL */ `
  query SyncUserConcepts(
    $filter: ModelUserConceptsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        defaultConcept
        concepts
        conceptsRoles
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        transactionID
        date
        guestName
        guestPhone
        guestID
        guestGroup
        guestsNames
        bookingID
        bookingDate
        amount_cents
        status
        failureReason
        type
        timeSlots
        tables
        conceptID
        currency
        refund
        orderID
        ownerID
        refunded_amount_cents
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTransactions = /* GraphQL */ `
  query SyncTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        transactionID
        date
        guestName
        guestPhone
        guestID
        guestGroup
        guestsNames
        bookingID
        bookingDate
        amount_cents
        status
        failureReason
        type
        timeSlots
        tables
        conceptID
        currency
        refund
        orderID
        ownerID
        refunded_amount_cents
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const bookingTransactions = /* GraphQL */ `
  query BookingTransactions(
    $bookingID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingTransactions(
      bookingID: $bookingID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionID
        date
        guestName
        guestPhone
        guestID
        guestGroup
        guestsNames
        bookingID
        bookingDate
        amount_cents
        status
        failureReason
        type
        timeSlots
        tables
        conceptID
        currency
        refund
        orderID
        ownerID
        refunded_amount_cents
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPaymentStat = /* GraphQL */ `
  query GetPaymentStat($id: ID!) {
    getPaymentStat(id: $id) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPaymentStats = /* GraphQL */ `
  query ListPaymentStats(
    $filter: ModelPaymentStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        conceptID
        year
        month
        monthName
        earning
        refund
        earningTrxCount
        refundTrxCount
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPaymentStats = /* GraphQL */ `
  query SyncPaymentStats(
    $filter: ModelPaymentStatFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPaymentStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        conceptID
        year
        month
        monthName
        earning
        refund
        earningTrxCount
        refundTrxCount
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBookingGuest = /* GraphQL */ `
  query GetBookingGuest($id: ID!) {
    getBookingGuest(id: $id) {
      id
      bookingID
      guestName
      paidAmount
      paymentAmount
      paymentStatus
      transactionID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBookingGuests = /* GraphQL */ `
  query ListBookingGuests(
    $filter: ModelBookingGuestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingGuests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingID
        guestName
        paidAmount
        paymentAmount
        paymentStatus
        transactionID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBookingGuests = /* GraphQL */ `
  query SyncBookingGuests(
    $filter: ModelBookingGuestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookingGuests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bookingID
        guestName
        paidAmount
        paymentAmount
        paymentStatus
        transactionID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      conceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      precedence
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        preparationAreaID
        subCategory
        name
        guestView
        guestOrder
        staffOrder
        image
        precedence
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        preparationAreaID
        subCategory
        name
        guestView
        guestOrder
        staffOrder
        image
        precedence
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const categoryByConceptID = /* GraphQL */ `
  query CategoryByConceptID(
    $conceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryByConceptID(
      conceptID: $conceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        preparationAreaID
        subCategory
        name
        guestView
        guestOrder
        staffOrder
        image
        precedence
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMenuItem = /* GraphQL */ `
  query GetMenuItem($id: ID!) {
    getMenuItem(id: $id) {
      id
      conceptID
      kioskID
      name
      description
      precedence
      image
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      ratings
      prices
      choiceGroups
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        kioskID
        name
        description
        precedence
        image
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        ratings
        prices
        choiceGroups
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMenuItems = /* GraphQL */ `
  query SyncMenuItems(
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMenuItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        kioskID
        name
        description
        precedence
        image
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        ratings
        prices
        choiceGroups
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const menuItemByConceptID = /* GraphQL */ `
  query MenuItemByConceptID(
    $conceptID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemByConceptID(
      conceptID: $conceptID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        kioskID
        name
        description
        precedence
        image
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        ratings
        prices
        choiceGroups
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const menuItemByCategoryID = /* GraphQL */ `
  query MenuItemByCategoryID(
    $categoryID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemByCategoryID(
      categoryID: $categoryID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        kioskID
        name
        description
        precedence
        image
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        ratings
        prices
        choiceGroups
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      conceptID
      name
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPrices = /* GraphQL */ `
  query SyncPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const priceByConceptID = /* GraphQL */ `
  query PriceByConceptID(
    $conceptID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    priceByConceptID(
      conceptID: $conceptID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        name
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChoiceGroups = /* GraphQL */ `
  query GetChoiceGroups($id: ID!) {
    getChoiceGroups(id: $id) {
      id
      conceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChoiceGroups = /* GraphQL */ `
  query ListChoiceGroups(
    $filter: ModelChoiceGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChoiceGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChoiceGroups = /* GraphQL */ `
  query SyncChoiceGroups(
    $filter: ModelChoiceGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChoiceGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const choiceGroupsByConceptID = /* GraphQL */ `
  query ChoiceGroupsByConceptID(
    $conceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChoiceGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    choiceGroupsByConceptID(
      conceptID: $conceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChoice = /* GraphQL */ `
  query GetChoice($id: ID!) {
    getChoice(id: $id) {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChoices = /* GraphQL */ `
  query ListChoices(
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChoices = /* GraphQL */ `
  query SyncChoices(
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChoices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const choiceByConceptID = /* GraphQL */ `
  query ChoiceByConceptID(
    $conceptID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    choiceByConceptID(
      conceptID: $conceptID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const choicesByChoiceGroupIDAndPrecedence = /* GraphQL */ `
  query ChoicesByChoiceGroupIDAndPrecedence(
    $choiceGroupID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    choicesByChoiceGroupIDAndPrecedence(
      choiceGroupID: $choiceGroupID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        nameMultiLanguages
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        notes
        image
        multiLanguages
        pending
        delivered
        __typename
      }
      specialRequest
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        userID
        orderedItems {
          menuItemID
          symphonyID
          preparationAreaID
          name
          nameMultiLanguages
          quantity
          choiceIDs
          choiceNames
          choiceSymphonyID
          extraChoiceIDs
          extraChoiceSymphonyID
          price
          priceName
          notes
          image
          multiLanguages
          pending
          delivered
          __typename
        }
        specialRequest
        totalPrice
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCarts = /* GraphQL */ `
  query SyncCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        userID
        orderedItems {
          menuItemID
          symphonyID
          preparationAreaID
          name
          nameMultiLanguages
          quantity
          choiceIDs
          choiceNames
          choiceSymphonyID
          extraChoiceIDs
          extraChoiceSymphonyID
          price
          priceName
          notes
          image
          multiLanguages
          pending
          delivered
          __typename
        }
        specialRequest
        totalPrice
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const cartByUserID = /* GraphQL */ `
  query CartByUserID(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        userID
        orderedItems {
          menuItemID
          symphonyID
          preparationAreaID
          name
          nameMultiLanguages
          quantity
          choiceIDs
          choiceNames
          choiceSymphonyID
          extraChoiceIDs
          extraChoiceSymphonyID
          price
          priceName
          notes
          image
          multiLanguages
          pending
          delivered
          __typename
        }
        specialRequest
        totalPrice
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminStatus = /* GraphQL */ `
  query GetAdminStatus($id: ID!) {
    getAdminStatus(id: $id) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminStatuses = /* GraphQL */ `
  query ListAdminStatuses(
    $filter: ModelAdminStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        accountID
        name
        averageCallTime
        numberOfCalls
        idelDuration
        activeDuration
        awayDuration
        lastCallTime
        agentBarAverageCallTime
        agentBarNumberOfCalls
        type
        waiterStatus
        totalAverageRatio
        performancePerDay
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminStatuses = /* GraphQL */ `
  query SyncAdminStatuses(
    $filter: ModelAdminStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        accountID
        name
        averageCallTime
        numberOfCalls
        idelDuration
        activeDuration
        awayDuration
        lastCallTime
        agentBarAverageCallTime
        agentBarNumberOfCalls
        type
        waiterStatus
        totalAverageRatio
        performancePerDay
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ByTotalAverageRatio = /* GraphQL */ `
  query ByTotalAverageRatio(
    $name: String!
    $totalAverageRatio: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByTotalAverageRatio(
      name: $name
      totalAverageRatio: $totalAverageRatio
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        accountID
        name
        averageCallTime
        numberOfCalls
        idelDuration
        activeDuration
        awayDuration
        lastCallTime
        agentBarAverageCallTime
        agentBarNumberOfCalls
        type
        waiterStatus
        totalAverageRatio
        performancePerDay
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOnlineOrder = /* GraphQL */ `
  query GetOnlineOrder($id: ID!) {
    getOnlineOrder(id: $id) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      userID
      userName
      dispatcherID
      dispatcherName
      cartID
      tenderId
      employeeId
      rvcId
      checkNumber
      totalAmount
      deliveryFee
      orderTime
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        nameMultiLanguages
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        notes
        image
        multiLanguages
        pending
        delivered
        __typename
      }
      pendingItems {
        menuItemID
        symphonyID
        preparationAreaID
        name
        nameMultiLanguages
        quantity
        choiceIDs
        choiceNames
        choiceSymphonyID
        extraChoiceIDs
        extraChoiceSymphonyID
        price
        priceName
        notes
        image
        multiLanguages
        pending
        delivered
        __typename
      }
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      acceptedByWaiterID
      acceptedByWaiterName
      deliveredByWaiterID
      tableID
      tableName
      shift
      parentOrder
      preparationAreaID
      childrenStatus
      createdAt
      acceptedByWaiterAt
      sentToKitchenAt
      acceptedByKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      canceledAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOnlineOrders = /* GraphQL */ `
  query ListOnlineOrders(
    $filter: ModelOnlineOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnlineOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        statusID
        status
        reason
        orderType
        userID
        userName
        dispatcherID
        dispatcherName
        cartID
        tenderId
        employeeId
        rvcId
        checkNumber
        totalAmount
        deliveryFee
        orderTime
        specialRequest
        orderAddress
        orderedItems {
          menuItemID
          symphonyID
          preparationAreaID
          name
          nameMultiLanguages
          quantity
          choiceIDs
          choiceNames
          choiceSymphonyID
          extraChoiceIDs
          extraChoiceSymphonyID
          price
          priceName
          notes
          image
          multiLanguages
          pending
          delivered
          __typename
        }
        pendingItems {
          menuItemID
          symphonyID
          preparationAreaID
          name
          nameMultiLanguages
          quantity
          choiceIDs
          choiceNames
          choiceSymphonyID
          extraChoiceIDs
          extraChoiceSymphonyID
          price
          priceName
          notes
          image
          multiLanguages
          pending
          delivered
          __typename
        }
        paymentStatus
        paymentType
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        acceptedByWaiterID
        acceptedByWaiterName
        deliveredByWaiterID
        tableID
        tableName
        shift
        parentOrder
        preparationAreaID
        childrenStatus
        createdAt
        acceptedByWaiterAt
        sentToKitchenAt
        acceptedByKitchenAt
        readyAt
        outForDeliveryAt
        deliveredAt
        canceledAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOnlineOrders = /* GraphQL */ `
  query SyncOnlineOrders(
    $filter: ModelOnlineOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOnlineOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        statusID
        status
        reason
        orderType
        userID
        userName
        dispatcherID
        dispatcherName
        cartID
        tenderId
        employeeId
        rvcId
        checkNumber
        totalAmount
        deliveryFee
        orderTime
        specialRequest
        orderAddress
        orderedItems {
          menuItemID
          symphonyID
          preparationAreaID
          name
          nameMultiLanguages
          quantity
          choiceIDs
          choiceNames
          choiceSymphonyID
          extraChoiceIDs
          extraChoiceSymphonyID
          price
          priceName
          notes
          image
          multiLanguages
          pending
          delivered
          __typename
        }
        pendingItems {
          menuItemID
          symphonyID
          preparationAreaID
          name
          nameMultiLanguages
          quantity
          choiceIDs
          choiceNames
          choiceSymphonyID
          extraChoiceIDs
          extraChoiceSymphonyID
          price
          priceName
          notes
          image
          multiLanguages
          pending
          delivered
          __typename
        }
        paymentStatus
        paymentType
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        acceptedByWaiterID
        acceptedByWaiterName
        deliveredByWaiterID
        tableID
        tableName
        shift
        parentOrder
        preparationAreaID
        childrenStatus
        createdAt
        acceptedByWaiterAt
        sentToKitchenAt
        acceptedByKitchenAt
        readyAt
        outForDeliveryAt
        deliveredAt
        canceledAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ordersByConceptID = /* GraphQL */ `
  query OrdersByConceptID(
    $conceptID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOnlineOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByConceptID(
      conceptID: $conceptID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        statusID
        status
        reason
        orderType
        userID
        userName
        dispatcherID
        dispatcherName
        cartID
        tenderId
        employeeId
        rvcId
        checkNumber
        totalAmount
        deliveryFee
        orderTime
        specialRequest
        orderAddress
        orderedItems {
          menuItemID
          symphonyID
          preparationAreaID
          name
          nameMultiLanguages
          quantity
          choiceIDs
          choiceNames
          choiceSymphonyID
          extraChoiceIDs
          extraChoiceSymphonyID
          price
          priceName
          notes
          image
          multiLanguages
          pending
          delivered
          __typename
        }
        pendingItems {
          menuItemID
          symphonyID
          preparationAreaID
          name
          nameMultiLanguages
          quantity
          choiceIDs
          choiceNames
          choiceSymphonyID
          extraChoiceIDs
          extraChoiceSymphonyID
          price
          priceName
          notes
          image
          multiLanguages
          pending
          delivered
          __typename
        }
        paymentStatus
        paymentType
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        acceptedByWaiterID
        acceptedByWaiterName
        deliveredByWaiterID
        tableID
        tableName
        shift
        parentOrder
        preparationAreaID
        childrenStatus
        createdAt
        acceptedByWaiterAt
        sentToKitchenAt
        acceptedByKitchenAt
        readyAt
        outForDeliveryAt
        deliveredAt
        canceledAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderStatus = /* GraphQL */ `
  query GetOrderStatus($id: ID!) {
    getOrderStatus(id: $id) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrderStatuses = /* GraphQL */ `
  query ListOrderStatuses(
    $filter: ModelOrderStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        color
        precedence
        accessibleStatus
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderStatuses = /* GraphQL */ `
  query SyncOrderStatuses(
    $filter: ModelOrderStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        color
        precedence
        accessibleStatus
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDiscountCoupon = /* GraphQL */ `
  query GetDiscountCoupon($id: ID!) {
    getDiscountCoupon(id: $id) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDiscountCoupons = /* GraphQL */ `
  query ListDiscountCoupons(
    $filter: ModelDiscountCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscountCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        discountPercent
        usedBy
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDiscountCoupons = /* GraphQL */ `
  query SyncDiscountCoupons(
    $filter: ModelDiscountCouponFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscountCoupons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        discountPercent
        usedBy
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMobileNotification = /* GraphQL */ `
  query GetMobileNotification($id: ID!) {
    getMobileNotification(id: $id) {
      id
      title
      description
      body
      default
      event
      image
      concepts
      condition
      startDate
      endDate
      group
      read
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMobileNotifications = /* GraphQL */ `
  query ListMobileNotifications(
    $filter: ModelMobileNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMobileNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        body
        default
        event
        image
        concepts
        condition
        startDate
        endDate
        group
        read
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMobileNotifications = /* GraphQL */ `
  query SyncMobileNotifications(
    $filter: ModelMobileNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMobileNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        body
        default
        event
        image
        concepts
        condition
        startDate
        endDate
        group
        read
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserPushToken = /* GraphQL */ `
  query GetUserPushToken($id: ID!) {
    getUserPushToken(id: $id) {
      id
      userID
      token
      reciveNotification
      type
      os
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserPushTokens = /* GraphQL */ `
  query ListUserPushTokens(
    $filter: ModelUserPushTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPushTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        token
        reciveNotification
        type
        os
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserPushTokens = /* GraphQL */ `
  query SyncUserPushTokens(
    $filter: ModelUserPushTokenFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserPushTokens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        token
        reciveNotification
        type
        os
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotificationReceiver = /* GraphQL */ `
  query GetNotificationReceiver($id: ID!) {
    getNotificationReceiver(id: $id) {
      id
      userID
      notificationID
      seen
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNotificationReceivers = /* GraphQL */ `
  query ListNotificationReceivers(
    $filter: ModelNotificationReceiverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationReceivers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        notificationID
        seen
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotificationReceivers = /* GraphQL */ `
  query SyncNotificationReceivers(
    $filter: ModelNotificationReceiverFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotificationReceivers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        notificationID
        seen
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      id
      conceptID
      location
      deliveryFee
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        location
        deliveryFee
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncZones = /* GraphQL */ `
  query SyncZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncZones(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        location
        deliveryFee
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserIP = /* GraphQL */ `
  query GetUserIP($id: ID!) {
    getUserIP(id: $id) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserIPS = /* GraphQL */ `
  query ListUserIPS(
    $filter: ModelUserIPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserIPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ip
        date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserIPS = /* GraphQL */ `
  query SyncUserIPS(
    $filter: ModelUserIPFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserIPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ip
        date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMenuRotation = /* GraphQL */ `
  query GetMenuRotation($id: ID!) {
    getMenuRotation(id: $id) {
      id
      conceptID
      precedence
      name
      multiLanguages
      startDate
      endDate
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMenuRotations = /* GraphQL */ `
  query ListMenuRotations(
    $filter: ModelMenuRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuRotations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        precedence
        name
        multiLanguages
        startDate
        endDate
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMenuRotations = /* GraphQL */ `
  query SyncMenuRotations(
    $filter: ModelMenuRotationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMenuRotations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        precedence
        name
        multiLanguages
        startDate
        endDate
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const menuRotationByConceptID = /* GraphQL */ `
  query MenuRotationByConceptID(
    $conceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuRotationByConceptID(
      conceptID: $conceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        precedence
        name
        multiLanguages
        startDate
        endDate
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMainCategory = /* GraphQL */ `
  query GetMainCategory($id: ID!) {
    getMainCategory(id: $id) {
      id
      conceptID
      name
      subName
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMainCategories = /* GraphQL */ `
  query ListMainCategories(
    $filter: ModelMainCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMainCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        subName
        precedence
        openTime
        closeTime
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMainCategories = /* GraphQL */ `
  query SyncMainCategories(
    $filter: ModelMainCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMainCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        subName
        precedence
        openTime
        closeTime
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mainCategoryByConceptID = /* GraphQL */ `
  query MainCategoryByConceptID(
    $conceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMainCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mainCategoryByConceptID(
      conceptID: $conceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        name
        subName
        precedence
        openTime
        closeTime
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSubCategory = /* GraphQL */ `
  query GetSubCategory($id: ID!) {
    getSubCategory(id: $id) {
      id
      menuRotationsID
      conceptID
      name
      subName
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSubCategories = /* GraphQL */ `
  query ListSubCategories(
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        menuRotationsID
        conceptID
        name
        subName
        precedence
        openTime
        closeTime
        mainCategoryID
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSubCategories = /* GraphQL */ `
  query SyncSubCategories(
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        menuRotationsID
        conceptID
        name
        subName
        precedence
        openTime
        closeTime
        mainCategoryID
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const subCategoryByConceptID = /* GraphQL */ `
  query SubCategoryByConceptID(
    $conceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoryByConceptID(
      conceptID: $conceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        menuRotationsID
        conceptID
        name
        subName
        precedence
        openTime
        closeTime
        mainCategoryID
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getShift = /* GraphQL */ `
  query GetShift($id: ID!) {
    getShift(id: $id) {
      id
      accountID
      name
      precedence
      startTime
      endTime
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listShifts = /* GraphQL */ `
  query ListShifts(
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        precedence
        startTime
        endTime
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncShifts = /* GraphQL */ `
  query SyncShifts(
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShifts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        precedence
        startTime
        endTime
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPreparationArea = /* GraphQL */ `
  query GetPreparationArea($id: ID!) {
    getPreparationArea(id: $id) {
      id
      accountID
      name
      isAutoAccept
      hasKDS
      hasPrinter
      printerIP
      printerPort
      users
      deleted
      precedence
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPreparationAreas = /* GraphQL */ `
  query ListPreparationAreas(
    $filter: ModelPreparationAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreparationAreas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        isAutoAccept
        hasKDS
        hasPrinter
        printerIP
        printerPort
        users
        deleted
        precedence
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPreparationAreas = /* GraphQL */ `
  query SyncPreparationAreas(
    $filter: ModelPreparationAreaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPreparationAreas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        isAutoAccept
        hasKDS
        hasPrinter
        printerIP
        printerPort
        users
        deleted
        precedence
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQRcode = /* GraphQL */ `
  query GetQRcode($id: ID!) {
    getQRcode(id: $id) {
      id
      tableID
      conceptID
      tableName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listQRcodes = /* GraphQL */ `
  query ListQRcodes(
    $filter: ModelQRcodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQRcodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tableID
        conceptID
        tableName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQRcodes = /* GraphQL */ `
  query SyncQRcodes(
    $filter: ModelQRcodeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQRcodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tableID
        conceptID
        tableName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCallWaiter = /* GraphQL */ `
  query GetCallWaiter($id: ID!) {
    getCallWaiter(id: $id) {
      id
      conceptID
      tableID
      tableName
      waiterID
      waiterName
      userID
      count
      status
      deleted
      createdAt
      acceptedAt
      arrivedAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCallWaiters = /* GraphQL */ `
  query ListCallWaiters(
    $filter: ModelCallWaiterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCallWaiters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        tableID
        tableName
        waiterID
        waiterName
        userID
        count
        status
        deleted
        createdAt
        acceptedAt
        arrivedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCallWaiters = /* GraphQL */ `
  query SyncCallWaiters(
    $filter: ModelCallWaiterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCallWaiters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        tableID
        tableName
        waiterID
        waiterName
        userID
        count
        status
        deleted
        createdAt
        acceptedAt
        arrivedAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
