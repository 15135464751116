import { CreateMenuItemInput, UpdateMenuItemInput } from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { ChoiceGroups, MenuItem } from "../models";
import { createMenuItem, updateMenuItem } from "../graphql/mutations";
import { getMenuItem, listMenuItems } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import {
  setListing,
  setNextToken,
  setLastIndex,
  setPagination,
  setAllListing,
  nextAction,
} from "../store/ducks/menuItem";
import { extractSelectedCheckboxes, getDateFormatted, getUAEDateTimeFormatted } from "../helpers/utils";
import { Languages, OrderStatus } from "../constants/enums";
import useCategory from "./useCategory";
import { onCreateMenuItem } from "../graphql/subscriptions";
import { getTimeInUAE } from "../helpers/utils";
import useGeneralPagination from "./useGeneralPagination";

export interface MenuItemDailyConsumption {
  date: string;
  year: number;
  month: number;
  day: number;
  data: {
    consumption: number;
    amount: number;
    price: number;
    avgPreparationTime: number;
    shifts: any;
  };
}

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm, showWarning } = useApp();
  const { categoriesGetName } = useCategory("categories", "category");
  const language = useSelector((state: any) => state.accounts.language);

  const session = useSelector((state: any) => state.app.session);
  const priceListing = useSelector((state: any) => state.prices.listing);
  const menuItemsListing = useSelector(
    (state: any) => state.menuItems.allListing
  );

  const nextToken = useSelector((state: any) => state.menuItems.nextToken);
  const paginationListing = useSelector(
    (state: any) => state.menuItems.pagination
  );

  // Category
  const categoriesListing = useSelector(
    (state: any) => state.categories.allListing
  );

  const accountsSelected = useSelector((state: any) => state.accounts.selected);
  const featuresAllListing = useSelector(
    (state: any) => state.features.listingAll
  );

  const storedLimit = useSelector((state: any) => state.menuItems.limit);
  const previousTokens = useSelector(
    (state: any) => state.menuItems.previousTokens
  );
  const { generalFetch, generalFetchAllByConceptID } = useGeneralPagination(
    listingName,
    singleName
  );

  function checkFeature(slug: any): Boolean {
    let feature: any[] = [];
    if (accountsSelected && accountsSelected.features) {
      feature = featuresAllListing.filter(function (feature: any) {
        return (
          slug.toLowerCase() === feature.slug.toLowerCase() &&
          accountsSelected.features.indexOf(feature.id) !== -1
        );
      });
    }
    if (feature.length > 0) {
      return true;
    }

    return false;
  }

  async function fetchPaginateFilter(props: any) {
    try {
      const { limit, conceptID, searchText } = props;

      let requestLimit = limit ? limit : storedLimit;
      let requestToken = nextToken;
      let requestPreviousTokens = previousTokens;
      let listing: any[] = [];

      const filter: any = {
        deleted: { eq: "0" },
      };

      if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }

      let itemsList: any = null;
      let currentNextToken;

      if (searchText.length > 0) {
        let filteredRows: any[] = [];
        const searchResultsSet = new Set();
        do {
          // fetch again
          itemsList = await API.graphql<GraphQLQuery<MenuItem>>({
            query: listMenuItems,
            variables: {
              filter: filter,
              limit: requestLimit ? requestLimit : 10,
              nextToken: requestToken,
            },
            authMode: session
              ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              : GRAPHQL_AUTH_MODE.AWS_IAM,
          });
          let queryFilteredRows = itemsList.data.listMenuItems.items.filter(
            (row: any) => row.name?.includes(searchText)
          );
          filteredRows = [...filteredRows, ...queryFilteredRows];

          currentNextToken = itemsList?.data?.listMenuItems?.nextToken;
          dispatch(nextAction(currentNextToken, requestPreviousTokens));
          dispatch(setNextToken(currentNextToken));

          requestToken = currentNextToken;

          filteredRows.forEach((item: any) => searchResultsSet.add(item));
          let searchResultsSetArray = Array.from(searchResultsSet);
          filteredRows = [...searchResultsSetArray];
          listing = [...listing, ...itemsList.data.listMenuItems.items];
        } while (
          (filteredRows.length < limit || filteredRows.length / limit < 0) &&
          currentNextToken !== null
        );
        return { listing, currentNextToken };
      } else {
        do {
          // fetch again
          itemsList = await API.graphql<GraphQLQuery<MenuItem>>({
            query: listMenuItems,
            variables: {
              filter: filter,
              limit: requestLimit ? requestLimit : 10,
              nextToken: requestToken,
            },
            authMode: session
              ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              : GRAPHQL_AUTH_MODE.AWS_IAM,
          });

          currentNextToken = itemsList?.data?.listMenuItems?.nextToken;
          dispatch(nextAction(currentNextToken, requestPreviousTokens));
          dispatch(setNextToken(currentNextToken));

          requestToken = currentNextToken;
          listing = [...listing, ...itemsList.data.listMenuItems.items];
        } while (
          (listing.length < limit || listing.length / limit < 0) &&
          currentNextToken !== null
        );
        return { listing, currentNextToken };
      }
    } catch (err: Error | any) {
      console.log(err);
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );

      return [];
    }
  }

  // Fetch with pagination, using concept index
  async function fetch(params: ListingByConceptVariables) {
    try {
      // const { conceptID, searchText, limit, startIndex } = params;
      const listing = await generalFetch(params, "menuItemByConceptID");
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  // Fetch without pagination using concept index
  async function fetchAllByConceptID(params: ListingByConceptVariables) {
    try {
      // const { conceptID, searchText, limit } = params;
      const listing = await generalFetchAllByConceptID(
        params,
        "menuItemByConceptID"
      );
      dispatch(setAllListing(listing));
      return listing;

      // if (
      //   menuItemsListing.length === 0 ||
      //   conceptID !== menuItemsListing[0].conceptID
      // ) {
      // } else {
      //   return menuItemsListing;
      // }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const menuItem: any = await API.graphql({
        query: getMenuItem,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return menuItem.data.getMenuItem;
    } catch (err) {
      throw err;
    }
  }

  async function getChoiceGroups(resourceId: string) {
    try {
      const choiceGroups: ChoiceGroups[] = [];

      const single: MenuItem | undefined = await get(resourceId);

      if (!single) return choiceGroups;

      if (single.choiceGroups && single.choiceGroups.length === 0)
        return choiceGroups;

      if (single.choiceGroups && single.choiceGroups.length > 0) {
        return choiceGroups;
      }
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    try {
      if (!data.name) {
        showError("Name is required");
        return;
      }

      if (!data.categoryID) {
        showError("Category is required");
        return;
      }
      const choiceGroups: string[] = extractSelectedCheckboxes(
        "choiceGroups_",
        data
      );

      let hasFeature = checkFeature("waiterorder");
      let prices: any = new Set();
      if (hasFeature) {
        prices = Array.from(prices);
      } else {
        if (!priceListing || priceListing.length === 0) {
          showError("Price is required");
          return null;
        } else {
          for (let price of priceListing) {
            prices.add(price.id);
          }
          prices = Array.from(prices);
        }
      }

      let multiLanguages: any = [[`${Languages.ENGLISH}-name`, data.name]];
      if (data.description) {
        multiLanguages.push([
          `${Languages.ENGLISH}-description`,
          data.description,
        ]);
      }
      multiLanguages = JSON.stringify(multiLanguages);

      const createInput: CreateMenuItemInput = {
        conceptID: data.conceptID,

        name: data.name.toLowerCase().trim(),
        description: data.description ? data.description : "",
        precedence: data.precedence ? data.precedence : "0",
        image: data.image
          ? data.image.fileUrl
            ? data.image.fileUrl
            : data.image
          : "1697485295279-AIRWAYS.png",
        categoryID: data.categoryID,
        outOfStock: data.outOfStock ? data.outOfStock : false,
        enabled: data.enabled ? data.enabled : true,
        symphonyID: data.symphonyID ? data.symphonyID : "0",
        prices: prices,
        choiceGroups: choiceGroups ? choiceGroups : [],
        hasLimitedQuantity: data.hasLimitedQuantity
          ? data.hasLimitedQuantity
          : false,
        quantity: data.quantity ? parseInt(data.quantity) : 0,
        autoRestock: data.autoRestock ? data.autoRestock : false,
        restockQuantity: data.restockQuantity
          ? parseInt(data.restockQuantity)
          : 0,
        multiLanguages: multiLanguages,
        createdAt: getUAEDateTimeFormatted(),
        createdByID: userID,
        createdByName: userName,
        quantityOrdered: 0,
      };

      const MenuItem = await API.graphql<GraphQLQuery<MenuItem>>({
        query: createMenuItem,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));
      showConfirm(`New ${singleName} has been created successfully`);

      return MenuItem;
    } catch (err) {
      showWarning(`Failed to create new ${singleName}`);
      console.log(err);
    }
  }

  function formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  async function update(params: any, session: any) {
    try {
      const { data } = params;

      let originalMenuItems: any = [];
      if (data.itemCounts) {
        //  console.log({ itemCounts: data.itemCounts });

        for (let item in data.itemCounts) {
          let menuItem = await get({ id: item });
          originalMenuItems.push(menuItem);
          if (
            menuItem.hasLimitedQuantity &&
            menuItem.quantity >= data.itemCounts[item]
          ) {
            showError(
              "Only " + menuItem.quantity + " items left of " + menuItem.name
            );
            return null;
          }
        }
      } else if (data.previousQuantity) {
        for (let item in data.previousQuantity) {
          let menuItem = await get({ id: item });
          originalMenuItems.push(menuItem);
        }
      } else {
        let menuItem = await get(params);
        originalMenuItems.push(menuItem);
      }
      let MenuItem: any = [];

      for (let i = 0; i < originalMenuItems.length; i++) {
        let multiLanguages: any = [];
        if (originalMenuItems[i].multiLanguages) {
          multiLanguages = new Map(
            JSON.parse(originalMenuItems[i].multiLanguages)
          );
          if (data.name) {
            multiLanguages.set(`${language}-name`, data.name);
          }
          if (data.description) {
            multiLanguages.set(`${language}-description`, data.description);
          }
          multiLanguages = JSON.stringify(Array.from(multiLanguages.entries()));
        } else {
          if (data.name) {
            multiLanguages.push([`${language}-name`, data.name]);
          }
          if (data.description) {
            multiLanguages.push([`${language}-description`, data.description]);
          }
          multiLanguages = JSON.stringify(multiLanguages);
        }

        let finalQuantity = originalMenuItems[i].quantity;
        let orderedQuantity = 0;

        let performancePerDay: string[] = [];
        let d = getTimeInUAE();
        let dateString = formatDate(d);
        let newPerformancePerDay: MenuItemDailyConsumption = {
          date: dateString,
          year: d.getFullYear(),
          month: d.getMonth() + 1,
          day: d.getDate(),
          data: {
            consumption: 0,
            amount: 0,
            price: 0,
            avgPreparationTime: 0,
            shifts: {},
          },
        };
        let newPerformanceIndex = -1;

        if (originalMenuItems[i].performancePerDay) {
          performancePerDay = originalMenuItems[i].performancePerDay;
          for (let i = 0; i < performancePerDay.length; i++) {
            let performanceJSON: MenuItemDailyConsumption = await JSON.parse(
              performancePerDay[i]
            );
            if (performanceJSON.date === newPerformancePerDay.date) {
              newPerformancePerDay = JSON.parse(performancePerDay[i]);
              newPerformanceIndex = i;
            }
          }
        }
        if (data.quantity !== undefined) {
          finalQuantity = data.quantity
            ? parseInt(data.quantity)
            : originalMenuItems[i]!.quantity;
        }

        // Call Center Item Stock
        if (data.action !== null && originalMenuItems[i].hasLimitedQuantity) {
          if (data.action === "create") {
            finalQuantity =
              originalMenuItems[i].quantity -
              data.itemCounts[originalMenuItems[i].id];
          }
          if (data.action === "update") {
            finalQuantity =
              originalMenuItems[i].quantity +
              (data.previousQuantity[originalMenuItems[i].id] -
                data.itemCounts[originalMenuItems[i].id]);
          }
          if (data.action === "cancel") {
            finalQuantity =
              originalMenuItems[i].quantity +
              data.previousQuantity[originalMenuItems[i].id];
          }
        }

        // Online Ordering Consumption (enter this part if user send order to kitchen only)
        if (data.action !== null) {
          if (
            data.action === "create" &&
            data.orderStatus === OrderStatus.sentToKitchen
          ) {
            // Update consumption (Quantity, Amount, Price)
            newPerformancePerDay.data.consumption +=
              data.itemCounts[originalMenuItems[i].id].quantity;
            newPerformancePerDay.data.amount +=
              data.itemCounts[originalMenuItems[i].id].amount;
            newPerformancePerDay.data.price =
              data.itemCounts[originalMenuItems[i].id].price; // No increment as this is price per item

            if (newPerformancePerDay.data.shifts === undefined) {
              newPerformancePerDay.data.shifts = {};
            }

            if (newPerformancePerDay.data.shifts[data.shift] !== undefined) {
              newPerformancePerDay.data.shifts[data.shift] +=
                data.itemCounts[originalMenuItems[i].id].quantity;
            } else {
              // New consumption per shift
              newPerformancePerDay.data.shifts[data.shift] =
                data.itemCounts[originalMenuItems[i].id].quantity;
            }

            if (newPerformanceIndex === -1) {
              // Create new performance
              performancePerDay.push(JSON.stringify(newPerformancePerDay));
            } else {
              // Update current MI performance
              performancePerDay[newPerformanceIndex] =
                JSON.stringify(newPerformancePerDay);
            }
          } else if (
            data.orderStatus === OrderStatus.ready ||
            data.orderStatus === OrderStatus.waitingPickUp
          ) {
            // Update avg preparation time
            const oldAvg = newPerformancePerDay.data.avgPreparationTime;
            const newAvg =
              data.itemCounts[originalMenuItems[i].id].avgPreparationTime;
            const consumption = newPerformancePerDay.data.consumption;
            const currentAvg = (oldAvg + newAvg) / consumption;

            newPerformancePerDay.data.avgPreparationTime = currentAvg;

            // Update current MI performance
            performancePerDay[newPerformanceIndex] =
              JSON.stringify(newPerformancePerDay);
          }
        }

        let prices: any = new Set(originalMenuItems[i]!.prices);
        let choiceGroups: any = new Set();
        const updatedChoiceGroups: string[] = extractSelectedCheckboxes(
          "choiceGroups_",
          data
        );

        if (data.prices && data.prices.length > 0) {
          for (let price of data.prices) {
            prices.add(price);
          }
          prices = Array.from(prices);
        }

        if (updatedChoiceGroups && updatedChoiceGroups.length > 0) {
          for (let choiceGroup of updatedChoiceGroups) {
            choiceGroups.add(choiceGroup);
          }
          choiceGroups = Array.from(choiceGroups);
        } else {
          choiceGroups = new Set(originalMenuItems[i]!.choiceGroups);
        }

        const updateInput: UpdateMenuItemInput = {
          id: originalMenuItems[i].id,
          name:
            data.name && language === Languages.ENGLISH
              ? data.name.toLowerCase()
              : originalMenuItems[i]!.name,
          description:
            data.description && language === Languages.ENGLISH
              ? data.description
              : originalMenuItems[i]!.description,
          precedence: data.precedence
            ? data.precedence
            : originalMenuItems[i]!.precedence,
          image: data.image
            ? data.image.fileUrl
              ? data.image.fileUrl
              : data.image
            : originalMenuItems[i]!.image,
          categoryID: data.categoryID
            ? data.categoryID
            : originalMenuItems[i]!.categoryID,
          outOfStock:
            data.outOfStock !== undefined
              ? data.outOfStock
              : originalMenuItems[i]!.outOfStock,
          enabled:
            data.enabled !== undefined
              ? data.enabled
              : originalMenuItems[i]!.enabled,
          symphonyID: data.symphonyID
            ? data.symphonyID
            : originalMenuItems[i]!.symphonyID,
          prices: data.prices ? prices : originalMenuItems[i]!.prices,
          choiceGroups:
            updatedChoiceGroups.length > 0
              ? choiceGroups
              : originalMenuItems[i]!.choiceGroups,
          hasLimitedQuantity: data.hasLimitedQuantity
            ? data.hasLimitedQuantity
            : originalMenuItems[i]!.hasLimitedQuantity,
          quantity: finalQuantity,
          autoRestock: data.autoRestock
            ? data.autoRestock
            : originalMenuItems[i]!.autoRestock,
          restockQuantity: data.restockQuantity
            ? parseInt(data.restockQuantity)
            : originalMenuItems[i]!.restockQuantity,
          quantityOrdered: orderedQuantity,
          performancePerDay: performancePerDay,
          multiLanguages: multiLanguages,
          _version: originalMenuItems[i]._version,
        };

        MenuItem = await API.graphql<GraphQLQuery<MenuItem>>({
          query: updateMenuItem,
          variables: { input: updateInput },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
      }
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));
      if (data.itemCounts || data.previousQuantity) {
        return true;
      } else {
        return MenuItem.data.updateMenuItem;
      }
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: any) {
    let original = await get(params);
    const updateInput: UpdateMenuItemInput = {
      id: original.id, // if (
      //   categoriesListing.length === 0 ||
      //   conceptID !== categoriesListing[0].conceptID
      // ) {
      // } else {
      //   return categoriesListing;
      // }
      deleted: "1",
      _version: original._version,
    };

    await API.graphql<GraphQLQuery<MenuItem>>({
      query: updateMenuItem,
      variables: { input: updateInput },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    for (let i = 0; i < menuItemsListing.length; i++) {
      if (menuItemsListing[i].id === original.id) {
        menuItemsListing.splice(i, 1);

        dispatch(setListing(menuItemsListing));
        break;
      }
    }
    for (let i = 0; i < paginationListing.length; i++) {
      if (paginationListing[i].id === original.id) {
        paginationListing.splice(i, 1);
        dispatch(setPagination(paginationListing));
        break;
      }
    }

    showConfirm(`${singleName} has been moved to trash successfully`);
  }

  async function bulkTrash(params: any) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });
    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function exportAll(params: ListingByConceptVariables) {
    try {
      const data: MenuItem[] = await fetch(params);

      let exportedData = [];

      // Map category name
      for (let menuItem of data!) {
        let row: any = { ...menuItem };

        if (menuItem.categoryID) {
          row.category = categoriesGetName({
            id: menuItem.categoryID,
            listing: categoriesListing,
          });
        }

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  async function exportConsumption(params: any) {
    try {
      let exportedData = [];
      const { menuItemList, categoriesListing } = params;

      // Map category name
      for (let menuItem of menuItemList!) {
        let row: any = { ...menuItem };

        if (menuItem.categoryID) {
          row.categoryName = categoriesGetName({
            id: menuItem.categoryID,
            listing: categoriesListing,
          });
        }

        if (menuItem.categoryID) {
          row.categoryName = categoriesGetName({
            id: menuItem.categoryID,
            listing: categoriesListing,
          });
        }

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  function options(listing: MenuItem[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "quantity",
      numeric: false,
      disablePadding: false,
      label: "Stock Quantity",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
  ];
  const dataCells: readonly string[] = [
    "name",
    "description",
    "precedence",
    "quantity",
  ];

  const consumptionDataCells: readonly string[] = [
    "name",
    "categoryName",
    "consumption",
  ];

  const servingAreaDataCells: readonly string[] = [
    "name",
    "categoryName",
    "consumption",
    "avgPreparationTime",
  ];

  const headConsumptionCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },

    {
      id: "categoryID",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "consumption",
      numeric: false,
      disablePadding: false,
      label: "Consumption",
    },
    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
  ];

  const headServingAreaCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "categoryID",
      numeric: false,
      disablePadding: false,
      label: "Category",
    },
    {
      id: "consumption",
      numeric: false,
      disablePadding: false,
      label: "Consumption",
    },
    {
      id: "preparationTime",
      numeric: false,
      disablePadding: false,
      label: "Preparation Time",
    },
    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
  ];

  const api: any = {};

  api[`${listingName}Model`] = MenuItem as any;
  api[`${listingName}CreateSubscription`] = onCreateMenuItem;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;

  api[`${listingName}HeadConsumptionCells`] = headConsumptionCells;
  api[`${listingName}ConsumptionDataCells`] = consumptionDataCells;

  api[`${listingName}HeadServingAreaCells`] = headServingAreaCells;
  api[`${listingName}ServingAreaDataCells`] = servingAreaDataCells;

  api[`${listingName}Options`] = options;
  api[`${listingName}FetchPaginateFilter`] = fetchPaginateFilter;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAllByConceptID;
  api[`${listingName}FetchByConceptID`] = fetchAllByConceptID;

  api[`${listingName}Get`] = get;
  api[`${listingName}GetChoiceGroups`] = getChoiceGroups;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ExportConsumption`] = exportConsumption;
  api[`${listingName}ChangeListing`] = (listing: MenuItem[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeAllListing`] = (listing: MenuItem[]) =>
    dispatch(setAllListing(listing));

  return api;
};

export default useResource;
